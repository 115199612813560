import React, { useState } from 'react';
import { Switch } from '@chakra-ui/react';

import { updateTenantIsActiveCall } from '../../../api/functions/tenants.functions';
import { CustomToast } from '../../../components/CustomToast';
import lang from '../../../utils/lang';

export const IsActiveTenantSwitch = ({
  id,
  name,
  value,
}: {
  id: string;
  name: string;
  value: boolean;
}) => {
  const [switchValue, setSwitchValue] = useState(value);
  const { addToast } = CustomToast();

  const onChange = async (e: React.ChangeEvent<unknown>) => {
    try {
      const response = await updateTenantIsActiveCall(id, !switchValue);
      if (response?.status === 201) {
        const toastMessage = `${name}' has been ${
          !switchValue ? 'activated' : 'deactivated'
        }!`;
        const toastStatus = 'success';
        addToast(toastMessage, toastStatus);
        setSwitchValue(!switchValue);
      }
    } catch (error) {
      console.error(error);
      const toastMessage =
        error instanceof Error ? error.message : lang.errors.default;
      const toastStatus = 'error';
      addToast(toastMessage, toastStatus);
    }
  };

  return (
    <Switch data-testid={id} isChecked={switchValue} onChange={onChange} />
  );
};
