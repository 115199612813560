import { ComparableQuestions } from '@keyops-cep/api-client';

export function sortComparableQuestionsAscending(
  comparableQuestions: ComparableQuestions[],
  currentEngagementId: string
) {
  // Sort the comparableQuestions array in place
  comparableQuestions.sort((a, b) => {
    // Find the question with the currentId in each comparableQuestions object
    const questionA = a.questions.find(
      (question) => question.engagementId === currentEngagementId
    );
    const questionB = b.questions.find(
      (question) => question.engagementId === currentEngagementId
    );

    // Use the 'position' property to compare and sort in descending order
    // If the currentId is not found in one of the questions arrays, treat its position as Infinity to ensure it sorts to the end
    const positionA = questionA ? questionA.position : Infinity;
    const positionB = questionB ? questionB.position : Infinity;

    // Ascending order comparison
    return positionA - positionB;
  });

  return comparableQuestions;
}
