import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  loginApiCall,
  logoutApiCall,
  removeTokens,
  setNewTokens,
} from '../../api/functions/auth.functions';
import { parseJwt } from '../../utils/functions/common-utils';
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from '../../utils/get-tokens';

import AuthContext from './AuthContext';

const isLocalStorageHaveTokens = () => {
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();
  return accessToken && refreshToken;
};

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation();
  const [isLoggedInFromApi, setIsLoggedInFromApi] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isLocalStorageHaveTokens()) {
      authCheck();
    } else {
      setIsLoggedInFromApi(false);
    }
  }, [location?.pathname]);

  const authCheck = async () => {
    setLoading(true);
    const accessToken = getAccessToken();
    const decodedJwt = parseJwt(accessToken);
    const isExpired = decodedJwt && decodedJwt.exp * 1000 < Date.now();
    if (isExpired) {
      try {
        const response = await setNewTokens();
        // if user gets new tokens, replace them in local storage and log user in
        if (response?.status === 201) {
          setIsLoggedInFromApi(true);
        }
      } catch (error) {
        // else log user out
        console.error(error);
        setIsLoggedInFromApi(false);
      }
    } else {
      setIsLoggedInFromApi(true);
    }
    setLoading(false);
  };

  const handleGoogleLogin = async (idToken: string | undefined) => {
    setLoading(true);
    try {
      const response = await loginApiCall(idToken);
      if (response?.status === 200) {
        setIsLoggedInFromApi(true);
        setAccessToken(response.data.accessToken);
        setRefreshToken(response.data.refreshToken);
      }
    } catch (error) {
      console.error('**********Error in loginToApi**********');
      console.error(error);
      setTimeout(() => {
        handleGoogleLogout();
      }, 3000);
    }
    setLoading(false);
  };

  // call this function to sign out logged in user
  const handleGoogleLogout = async () => {
    setLoading(true);
    // wrapped in try/catch/finally as sometimes if the tokens are invalid
    // server throws an error, but on FE we still need to log user out.
    try {
      // call api to remove tokens from BE
      await logoutApiCall();
    } catch (error) {
      console.error(error);
    } finally {
      // remove stored tokens from local storage
      removeTokens();
      setIsLoggedInFromApi(false);
    }
    setLoading(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedInFromApi,
        loading,
        handleGoogleLogin,
        handleGoogleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
