export const parseJwt = (
  token: string | null
): {
  authenticatedUserId: string;
  exp: number;
} | null => {
  try {
    if (!token) return null;
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const parsedData = JSON.parse(rawData);
    return {
      authenticatedUserId: parsedData.authenticatedUserId,
      exp: parsedData.exp,
    };
  } catch (e) {
    return null;
  }
};

export const dateFormatter = (timestamp?: number): string | null => {
  if (!timestamp) {
    return null;
  }
  return new Date(timestamp).toLocaleString(['en-CA'], {
    formatMatcher: 'best fit',
    dateStyle: 'medium',
  });
};
