import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit, FaEnvelope } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Heading, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { AxiosError, AxiosResponse } from 'axios';

import { AdminDto } from '../../api/dto/admin.dto';
import {
  CreateUserDto,
  DisplayedUserDto,
  FullUserDto,
  UpdateUserDto,
} from '../../api/dto/user.dto';
import {
  createUserCall,
  getUserCall,
  getUsersCall,
  resendInviteCall,
  updateUserCall,
} from '../../api/functions/users.functions';
import { CustomBreadcrumb } from '../../components/CustomBreadcrumb';
import { CustomToast } from '../../components/CustomToast';
import GenericButton from '../../components/GenericButton';
import Loading from '../../components/Loading';
import { useTenant } from '../../hooks/useTenants';
import { dateFormatter } from '../../utils/functions/common-utils';
import { ROOT } from '../../utils/internal-routes';
import lang from '../../utils/lang';

import { UserModal } from './components/userModal';
import { UsersTable } from './components/usersTable';

//Build table logic
export type UsersTableHead = {
  id: string;
  displayName: string;
  email: string;
  isActive: boolean;
  registered: boolean;
  createdAt: Date;
  updatedAt: Date;
  actions: string;
};

export const buildUsersTableColumns = (
  handleUserAction: (userId: string) => void,
  handleResendInvite: (userId: string) => void
) => {
  const columnHelper = createColumnHelper<UsersTableHead>();
  const columns = [
    columnHelper.accessor('displayName', {
      cell: (info) => info.getValue(),
      header: 'Name',
    }),
    columnHelper.accessor('email', {
      cell: (info) => info.getValue(),
      header: 'Email',
    }),
    columnHelper.accessor('isActive', {
      cell: (info) =>
        info.getValue() ? (
          <CheckIcon aria-label="true-icon" color={'green'} />
        ) : (
          <CloseIcon aria-label="false-icon" color={'red'} />
        ),
      header: 'Active',
    }),
    columnHelper.accessor('registered', {
      cell: (info) =>
        info.getValue() ? (
          <CheckIcon aria-label="true-icon" color={'green'} />
        ) : (
          <CloseIcon aria-label="false-icon" color={'red'} />
        ),
      header: 'Signed up',
    }),
    columnHelper.accessor('createdAt', {
      cell: (info) => dateFormatter(info.getValue()?.getTime()),
      header: 'Creation date',
    }),
    columnHelper.accessor('id', {
      cell: (info) => info.getValue(),
      header: 'Id',
    }),
    columnHelper.accessor('actions', {
      cell: (info) => {
        return (
          <Text
            display="flex"
            justifyContent="space-between"
            alignContent="space-between"
          >
            <FaEdit onClick={() => handleUserAction(info.row.original.id)} />
            {!info.row.original.registered && (
              <FaEnvelope
                title="Resend Invite"
                onClick={() => handleResendInvite(info.row.original.id)}
              />
            )}
            {/* TODO: add a delete action here */}
            <MdDelete />
          </Text>
        );
      },
      header: 'Actions',
    }),
  ];

  return columns;
};

const buildUsersTableData = (users: DisplayedUserDto[]) => {
  const data: UsersTableHead[] = [];
  users.forEach((user) =>
    data.push({
      id: user.id,
      displayName: user.displayName,
      email: user.email,
      isActive: user.isActive,
      registered: user.registered,
      createdAt: new Date(user.createdAt),
      updatedAt: new Date(user.createdAt),
      actions: '<p><p>',
    })
  );

  return data;
};

export const Users: React.FunctionComponent = () => {
  const tenant = useTenant();
  const [users, setUsers] = useState([] as DisplayedUserDto[]);
  const [user, setUser] = useState({} as UpdateUserDto);
  const usersData: UsersTableHead[] = buildUsersTableData(users);

  // Handle notification toast (error, success...)
  const { addToast } = CustomToast();

  const fetchUser = useCallback(
    async (userId: string): Promise<FullUserDto | undefined> => {
      try {
        const response = await getUserCall(userId);
        if (response) return response.data;
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const fetchUsers = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getUsersCall();
      if (response) {
        const user = response.data?.filter((user: CreateUserDto) => {
          return tenant?.id ? user.tenantId === tenant?.id : true;
        });
        setUsers(user);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(`fetchUsers` + error);
      const toastMessage =
        error instanceof Error
          ? error.message
          : 'An unexpected error occurred. Please try again.';
      const toastStatus = 'error';
      addToast(toastMessage, toastStatus);
      setIsLoading(false);
    }
  }, [addToast, tenant]);

  useEffect(() => {
    fetchUsers();
  }, [tenant, user]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handle user creation and update modal and form
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleUserAction = async (userId?: string) => {
    if (userId) {
      const user = await fetchUser(userId);
      setUser(user as FullUserDto);
    } else {
      setUser({} as UpdateUserDto);
    }
    onOpen();
  };
  const handleResendInvite = async (userId?: string) => {
    if (!userId) {
      return;
    }
    try {
      const response = await resendInviteCall(userId);

      if (response?.status === 201) {
        const toastMessage = `Invite Resent.`;
        const toastStatus = 'success';
        onClose();
        addToast(toastMessage, toastStatus);
      }
    } catch (error) {
      let toastMessage =
        error instanceof Error ? error.message : lang.errors.default;
      const toastStatus = 'error';
      if (error instanceof AxiosError && error.response) {
        const errorMessages = error.response.data.message;
        if (Array.isArray(errorMessages)) {
          errorMessages.forEach((msg: string) => {
            toastMessage += msg + ' \n';
          });
        } else {
          toastMessage = errorMessages;
        }
      }
      addToast(toastMessage, toastStatus);
    }
  };

  const onFormSubmit = async (values: CreateUserDto | UpdateUserDto) => {
    let response: AxiosResponse | undefined;
    try {
      const payload = { ...values };
      //  Update case
      if ('id' in values) {
        response = await updateUserCall(payload as UpdateUserDto);
      }
      //  Creation case
      else {
        response = await createUserCall(payload);
      }

      if (response?.status === 201) {
        await fetchUsers();
        const toastMessage = `The user '${values.displayName}' has been ${
          'id' in values ? `updated` : `created`
        }!`;
        const toastStatus = 'success';
        onClose();
        addToast(toastMessage, toastStatus);
      }
    } catch (error) {
      let toastMessage =
        error instanceof Error ? error.message : lang.errors.default;
      const toastStatus = 'error';
      if (error instanceof AxiosError && error.response) {
        const errorMessages = error.response.data.message;
        if (Array.isArray(errorMessages)) {
          errorMessages.forEach((msg: string) => {
            toastMessage += msg + ' \n';
          });
        } else {
          toastMessage = errorMessages;
        }
      }
      addToast(toastMessage, toastStatus);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <CustomBreadcrumb
        list={[
          { text: 'Home', link: ROOT },
          {
            text: 'All users',
            link: '',
          },
        ]}
      />
      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        paddingLeft={5}
        paddingRight={5}
      >
        <Heading as="h1" fontSize={24} lineHeight={'32px'}>
          {lang.user.single.title} {isLoading ? `` : ` for ${tenant?.name}`}
        </Heading>
        <GenericButton
          type="button"
          text={lang.user.single.add.action}
          onClick={() => handleUserAction()}
        />
      </Stack>
      {isLoading ? (
        <Loading />
      ) : (
        <UsersTable
          data={usersData ?? {}}
          handleUserAction={handleUserAction}
          handleResendInvite={handleResendInvite}
        />
      )}

      <UserModal
        isAdmin={false}
        tenant={tenant}
        user={user}
        isOpen={isOpen}
        onClose={onClose}
        onFormSubmit={
          onFormSubmit as (values: AdminDto | CreateUserDto) => Promise<void>
        }
        setUser={setUser}
      />
    </>
  );
};
