import React, { useEffect } from 'react';

import useAuth from '../../HOCs/auth/useAuth';

const Logout: React.FC = () => {
  const { handleGoogleLogout } = useAuth();

  useEffect(() => {
    handleGoogleLogout();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <></>;
};

export default Logout;
