import React from 'react';
import { Button, Center, Text, UseModalProps } from '@chakra-ui/react';
import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';

import {
  CreateTenantDto,
  TENANT_COUNTRIES,
  UpdateTenantDto,
} from '../../../api/dto/tenant.dto';
import { CustomSelectInput } from '../../../components/Form/CustomSelectInput';
import { CustomTextInput } from '../../../components/Form/CustomTextInput';
import GenericButton from '../../../components/GenericButton';

const buildInitialValues = (
  tenant?: CreateTenantDto | UpdateTenantDto
): CreateTenantDto | UpdateTenantDto => {
  const basicTenant = {
    name: tenant ? tenant.name : '',
    country: tenant ? tenant.country : TENANT_COUNTRIES['CA'],
    isActive: tenant ? tenant.isActive : true,
  };
  if (tenant) {
    return {
      ...basicTenant,
      id: (tenant as UpdateTenantDto).id,
    } as UpdateTenantDto;
  }
  return { ...basicTenant } as CreateTenantDto;
};

// Define the form validation schema using Yup
const buildValidationSchema = () => {
  return yup.object({
    name: yup.string().required('Name is required'),
    country: yup.string(),
    isActive: yup.boolean(),
  });
};

export function TenantForm({
  onClose,
  onSubmit,
  tenant,
}: {
  onClose: UseModalProps['onClose'];
  onSubmit: (values: CreateTenantDto | UpdateTenantDto) => Promise<void>;
  tenant?: CreateTenantDto | UpdateTenantDto;
}) {
  const initialValues = buildInitialValues(tenant);
  const validationSchema = buildValidationSchema();
  const formik: FormikProps<CreateTenantDto | UpdateTenantDto> = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* Tenant Name */}
      <CustomTextInput
        name="name"
        label="name"
        placeholder="Client name"
        helperMessage=""
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isMandatory={true}
      />
      {formik.touched.name && formik.errors.name && (
        <Text as="sub" color="red">
          {formik.errors.name}
        </Text>
      )}
      {/* Tenant Country */}
      <CustomSelectInput
        name="country"
        label="Country"
        placeholder="Select a Country"
        value={formik.values.country}
        options={TENANT_COUNTRIES}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isMandatory={true}
      />

      {/* Submit */}
      <Center>
        {' '}
        <Button type="button" onClick={onClose} colorScheme="gray">
          Cancel
        </Button>
        <GenericButton
          type="submit"
          text="Save"
          onClick={formik.handleSubmit}
          size="md"
        />
      </Center>
    </form>
  );
}
