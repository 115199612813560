import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { ComparableEngagements, Engagement } from '@keyops-cep/api-client';

import { apiClient } from '../../api/swagger-codegen-api-config';
import { BackBreadcrumb } from '../../components/CustomBreadcrumb';
import { CustomToast } from '../../components/CustomToast';
import { GenericLink } from '../../components/GenericLink';
import Loading from '../../components/Loading';
import { useEngagement } from '../../hooks/useEngagements';
import { useTenant } from '../../hooks/useTenants';
import { CUSTOMER_UI_ENGAGEMENT_DETAILS } from '../../utils/external-routes';
import { sortComparableQuestionsAscending } from '../../utils/functions/compare.utils';
import { navigateToCustomerUi } from '../../utils/functions/navigate-to-customer-ui';
import { TENANTED_ENGAGEMENT_SINGLE_PAGE } from '../../utils/internal-routes';
import lang from '../../utils/lang';

import { ComparisonsTabs } from './components/comparisonsTabs';

export const EngagementsComparison: React.FunctionComponent = () => {
  const { addToast } = CustomToast();
  const tenant = useTenant();
  const { engagement: currentEngagement } = useEngagement();
  const { comparableEngagementId } = useParams();
  const [isLoading, setLoading] = useState(false);

  // Retrieve the comparable Engagement
  const [comparableEngagement, setComparableEngagement] =
    useState<Engagement>();

  useEffect(() => {
    handleGetSimilarEngagement();
    // eslint-disable-next-line
  }, [currentEngagement, comparableEngagementId]);

  // Get the engagement comparable to the current one
  const handleGetSimilarEngagement = async () => {
    setLoading(true);
    if (comparableEngagementId) {
      try {
        const result =
          await apiClient.engagementsApi.engagementControllerFindOne(
            comparableEngagementId
          );

        if (result.data) {
          setComparableEngagement(result.data);
          setLoading(false);
        }
      } catch (error) {
        addToast(lang.errors.default, `error`);
        setLoading(false);
      }
    }
  };

  // Retrieve the ComparableEngagements for these 2 Engagements
  const [comparableEngagements, setComparableEngagements] =
    useState<ComparableEngagements>();

  useEffect(() => {
    handleGetComparableEngagements();
    // eslint-disable-next-line
  }, [currentEngagement, comparableEngagementId]);

  // Get the comparableEngagements related to these 2 engagements
  const handleGetComparableEngagements = async () => {
    setLoading(true);
    if (comparableEngagementId && currentEngagement.id) {
      try {
        const result =
          await apiClient.comparableEngagementsApi.comparableEngagementsControllerGetOne(
            currentEngagement.id,
            comparableEngagementId
          );

        if (result.data) {
          console.log(
            `handleGetComparableEngagements > comparableEngagements`,
            result.data
          );
          // Sort ComparableQuestions by questions position
          setComparableEngagements({
            ...result.data,
            comparableQuestions: sortComparableQuestionsAscending(
              result.data?.comparableQuestions,
              currentEngagement.id
            ),
          });
          console.log(
            `handleGetComparableEngagements > AFTER SORT > comparableEngagements`,
            result.data
          );
          setLoading(false);
        }
      } catch (error) {
        addToast(lang.errors.default, `error`);
        setLoading(false);
      }
    }
  };

  const getRenderedView = () => {
    return (
      <Container maxWidth="1600px" width="100%" margin="auto">
        <BackBreadcrumb
          link={TENANTED_ENGAGEMENT_SINGLE_PAGE.replace(
            ':tenantId',
            currentEngagement.tenantId
          ).replace(':engagementId', currentEngagement.id)}
        />
        <Box margin={5}>
          <Heading as="h1" fontSize={24} lineHeight={'32px'} textAlign="left">
            Comparing {currentEngagement?.name} with{' '}
            {comparableEngagement?.name}
          </Heading>
        </Box>
        <Box margin={5} marginRight={0}>
          <Box pb={1}>
            <GenericLink
              mr={3}
              pr={3}
              text={currentEngagement.name}
              href={TENANTED_ENGAGEMENT_SINGLE_PAGE.replace(
                ':tenantId',
                currentEngagement.tenantId
              ).replace(':engagementId', currentEngagement.id)}
              borderRight={'solid black 0.5px'}
            />
            <GenericLink
              text={lang.engagement.single.preview}
              onClick={() => {
                navigateToCustomerUi(
                  CUSTOMER_UI_ENGAGEMENT_DETAILS.replace(
                    ':engagementId',
                    currentEngagement.id
                  )
                );
              }}
            />
          </Box>
          <Text>{currentEngagement?.displayTitle}</Text>
          <Text>{lang.engagement.single.comparableEngagement.themes}:</Text>
          <Text>{currentEngagement?.themes}</Text>
        </Box>

        <Box margin={5} mt={8} marginRight={0}>
          <Box pb={1}>
            <GenericLink
              mr={3}
              pr={3}
              text={comparableEngagement?.name as string}
              href={TENANTED_ENGAGEMENT_SINGLE_PAGE.replace(
                ':tenantId',
                comparableEngagement?.tenantId as string
              ).replace(':engagementId', comparableEngagement?.id as string)}
              borderRight={'solid black 0.5px'}
            />
            <GenericLink
              text={lang.engagement.single.preview}
              onClick={() => {
                navigateToCustomerUi(
                  CUSTOMER_UI_ENGAGEMENT_DETAILS.replace(
                    ':engagementId',
                    comparableEngagement?.id as string
                  )
                );
              }}
            />
          </Box>
          <Text>{comparableEngagement?.displayTitle}</Text>
          <Text>{lang.engagement.single.comparableEngagement.themes}:</Text>
          <Text>{comparableEngagement?.themes}</Text>
        </Box>

        {comparableEngagements?.comparableQuestions && comparableEngagement && (
          <ComparisonsTabs
            comparableEngagements={comparableEngagements}
            currentEngagementName={currentEngagement.name}
            comparableEngagementName={comparableEngagement?.name}
          />
        )}
      </Container>
    );
  };

  if (!tenant || !currentEngagement || !comparableEngagement || isLoading) {
    return <Loading />;
  }

  return getRenderedView();
};
