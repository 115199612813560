import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { updateRespondentDemographic } from '../../../api/functions/respondent-demographic.functions';
import { RespondentDemographic } from '..';

interface UpdateRespondentDemographicsModalProps {
  currentDemographics: RespondentDemographic[];
  isOpen: boolean;
  setIsOpen: (arg0: boolean) => void;
  onSave: () => void;
}

export const UpdateRespondentDemographicsModal = ({
  currentDemographics,
  isOpen,
  setIsOpen,
  onSave,
}: UpdateRespondentDemographicsModalProps) => {
  const [updatedDemographics, setUpdatedDemographics] =
    useState(currentDemographics);
  useEffect(() => {
    setUpdatedDemographics(currentDemographics);
  }, [currentDemographics]);

  const handleNameChange = (
    index: number,
    event: { target: { value: string } }
  ) => {
    const newDemographics = [...updatedDemographics];
    newDemographics[index].name = event.target.value;
    setUpdatedDemographics(newDemographics);
  };

  const handleValueChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newDemographics = [...updatedDemographics];
    newDemographics[index].value = event.target.value;
    setUpdatedDemographics(newDemographics);
  };

  const handleSubmit = async () => {
    for (const demographic of updatedDemographics) {
      await updateRespondentDemographic(demographic.id, {
        name: demographic.name,
        value: demographic.value,
      });
    }
    setIsOpen(false);
    onSave();
  };

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Demographics</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <Box>
              <FormLabel>Names:</FormLabel>
              {updatedDemographics.map((demographic, index) => (
                <FormControl key={index}>
                  <Input
                    type="text"
                    defaultValue={demographic.name}
                    onChange={(event) => handleNameChange(index, event)}
                  />
                </FormControl>
              ))}
            </Box>
            <Box>
              <FormLabel>Values:</FormLabel>
              {updatedDemographics.map((demographic, index) => (
                <FormControl key={index}>
                  <Input
                    type="text"
                    defaultValue={demographic.value}
                    onChange={(event) => handleValueChange(index, event)}
                  />
                </FormControl>
              ))}
            </Box>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Save
          </Button>
          <Button variant="ghost" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
