/* tslint:disable */
/* eslint-disable */
/**
 * Keyops CEP
 * Customer Engagement Platform API documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { CreateQuestionAnalysisDto } from '../models';
import { QuestionAnalysis } from '../models';
import { UpdateQuestionAnalysisDto } from '../models';
/**
 * QuestionAnalysesApi - axios parameter creator
 * @export
 */
export const QuestionAnalysesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a QuestionAnalysis
         * @param {CreateQuestionAnalysisDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionAnalysisControllerCreate: async (body: CreateQuestionAnalysisDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling questionAnalysisControllerCreate.');
            }
            const localVarPath = `/api/question-analyses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all QuestionAnalyses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionAnalysisControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/question-analyses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a QuestionAnalysis by it's ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionAnalysisControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling questionAnalysisControllerFindOne.');
            }
            const localVarPath = `/api/question-analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a QuestionAnalysis by it's ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionAnalysisControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling questionAnalysisControllerRemove.');
            }
            const localVarPath = `/api/question-analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a QuestionAnalysis
         * @param {UpdateQuestionAnalysisDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionAnalysisControllerUpdate: async (body: UpdateQuestionAnalysisDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling questionAnalysisControllerUpdate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling questionAnalysisControllerUpdate.');
            }
            const localVarPath = `/api/question-analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionAnalysesApi - functional programming interface
 * @export
 */
export const QuestionAnalysesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a QuestionAnalysis
         * @param {CreateQuestionAnalysisDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionAnalysisControllerCreate(body: CreateQuestionAnalysisDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<QuestionAnalysis>>> {
            const localVarAxiosArgs = await QuestionAnalysesApiAxiosParamCreator(configuration).questionAnalysisControllerCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets all QuestionAnalyses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionAnalysisControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<QuestionAnalysis>>>> {
            const localVarAxiosArgs = await QuestionAnalysesApiAxiosParamCreator(configuration).questionAnalysisControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a QuestionAnalysis by it's ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionAnalysisControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<QuestionAnalysis>>> {
            const localVarAxiosArgs = await QuestionAnalysesApiAxiosParamCreator(configuration).questionAnalysisControllerFindOne(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deletes a QuestionAnalysis by it's ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionAnalysisControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await QuestionAnalysesApiAxiosParamCreator(configuration).questionAnalysisControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates a QuestionAnalysis
         * @param {UpdateQuestionAnalysisDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionAnalysisControllerUpdate(body: UpdateQuestionAnalysisDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<QuestionAnalysis>>> {
            const localVarAxiosArgs = await QuestionAnalysesApiAxiosParamCreator(configuration).questionAnalysisControllerUpdate(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * QuestionAnalysesApi - factory interface
 * @export
 */
export const QuestionAnalysesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Creates a QuestionAnalysis
         * @param {CreateQuestionAnalysisDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionAnalysisControllerCreate(body: CreateQuestionAnalysisDto, options?: AxiosRequestConfig): Promise<AxiosResponse<QuestionAnalysis>> {
            return QuestionAnalysesApiFp(configuration).questionAnalysisControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all QuestionAnalyses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionAnalysisControllerFindAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<QuestionAnalysis>>> {
            return QuestionAnalysesApiFp(configuration).questionAnalysisControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a QuestionAnalysis by it's ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionAnalysisControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<QuestionAnalysis>> {
            return QuestionAnalysesApiFp(configuration).questionAnalysisControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a QuestionAnalysis by it's ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionAnalysisControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return QuestionAnalysesApiFp(configuration).questionAnalysisControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a QuestionAnalysis
         * @param {UpdateQuestionAnalysisDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionAnalysisControllerUpdate(body: UpdateQuestionAnalysisDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<QuestionAnalysis>> {
            return QuestionAnalysesApiFp(configuration).questionAnalysisControllerUpdate(body, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionAnalysesApi - object-oriented interface
 * @export
 * @class QuestionAnalysesApi
 * @extends {BaseAPI}
 */
export class QuestionAnalysesApi extends BaseAPI {
    /**
     * 
     * @summary Creates a QuestionAnalysis
     * @param {CreateQuestionAnalysisDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionAnalysesApi
     */
    public async questionAnalysisControllerCreate(body: CreateQuestionAnalysisDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<QuestionAnalysis>> {
        return QuestionAnalysesApiFp(this.configuration).questionAnalysisControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets all QuestionAnalyses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionAnalysesApi
     */
    public async questionAnalysisControllerFindAll(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<QuestionAnalysis>>> {
        return QuestionAnalysesApiFp(this.configuration).questionAnalysisControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets a QuestionAnalysis by it's ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionAnalysesApi
     */
    public async questionAnalysisControllerFindOne(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<QuestionAnalysis>> {
        return QuestionAnalysesApiFp(this.configuration).questionAnalysisControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Deletes a QuestionAnalysis by it's ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionAnalysesApi
     */
    public async questionAnalysisControllerRemove(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return QuestionAnalysesApiFp(this.configuration).questionAnalysisControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Updates a QuestionAnalysis
     * @param {UpdateQuestionAnalysisDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionAnalysesApi
     */
    public async questionAnalysisControllerUpdate(body: UpdateQuestionAnalysisDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<QuestionAnalysis>> {
        return QuestionAnalysesApiFp(this.configuration).questionAnalysisControllerUpdate(body, id, options).then((request) => request(this.axios, this.basePath));
    }
}
