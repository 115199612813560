import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { Center } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';

import { AdminDto } from '../../../api/dto/admin.dto';
import { CreateUserDto, UpdateUserDto } from '../../../api/dto/user.dto';
import { updateUserIsActiveCall } from '../../../api/functions/users.functions';
import { CustomToast } from '../../../components/CustomToast';
import { CustomEmailInput } from '../../../components/Form/CustomEmailInput';
import { CustomErrorText } from '../../../components/Form/CustomErrorText';
import { CustomSwitchInput } from '../../../components/Form/CustomSwitchInput';
import { CustomTextInput } from '../../../components/Form/CustomTextInput';
import GenericButton from '../../../components/GenericButton';
import lang from '../../../utils/lang';

import 'react-datepicker/dist/react-datepicker.css';

const buildInitialValues = (
  isAdmin: boolean,
  tenantId: string,
  user: CreateUserDto | UpdateUserDto | undefined
): CreateUserDto | UpdateUserDto | AdminDto => {
  const basicUser = {
    displayName: user?.displayName ?? '',
    email: user?.email ?? '',
  };
  if (isAdmin) {
    return basicUser as AdminDto;
  }
  const userDto = {
    ...basicUser,
    isActive: user?.isActive ?? true,
    registered: user?.registered ?? false,
    tenantId: user?.tenantId ?? tenantId,
  } as UpdateUserDto;
  if ((user as UpdateUserDto)?.id) {
    return {
      ...userDto,
      id: (user as UpdateUserDto)?.id,
    } as UpdateUserDto;
  }
  return userDto as CreateUserDto;
};

const buildValidationSchema = (isAdmin: boolean) => {
  return isAdmin
    ? yup.object({
        displayName: yup.string().required('Name is required'),
        email: yup
          .string()
          .email('Please provide a valid email')
          .required('Email is required'),
      })
    : yup.object({
        displayName: yup.string().required('Name is required'),
        email: yup
          .string()
          .email('Please provide a valid email')
          .required('Email is required'),
        isActive: yup.boolean(),
        activated: yup.boolean(),
        tenantId: yup.string().uuid(),
      });
};

export function UserForm({
  user,
  isAdmin,
  tenantId,
  onSubmit,
  setUser,
}: {
  user?: CreateUserDto | UpdateUserDto;
  isAdmin: boolean;
  tenantId: string | undefined;
  onSubmit: (values: CreateUserDto | AdminDto) => Promise<void>;
  setUser?: Dispatch<SetStateAction<UpdateUserDto>>;
}) {
  const initialValues = buildInitialValues(isAdmin, tenantId as string, user);
  const validationSchema = buildValidationSchema(isAdmin);
  const formik: FormikProps<CreateUserDto | AdminDto> = useFormik<
    CreateUserDto | AdminDto
  >({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { addToast } = CustomToast();

  // Handle the update of `isActive` user status, which uses a different endpoint
  const [isUpdate] = useState('id' in initialValues ? true : false);

  const handleIsActiveChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      formik.handleChange(e);

      const response = await updateUserIsActiveCall({
        id: (initialValues as UpdateUserDto).id,
        isActive: e.target.checked,
      });

      if (response?.status === 201) {
        const user = response.data;
        if (!setUser) {
          throw new Error(lang.errors.default);
        }
        setUser(user);

        const toastMessage = `User '${user.displayName}' is now  ${
          user.isActive ? 'activated' : 'deactivated'
        }`;
        const toastStatus = 'success';
        addToast(toastMessage, toastStatus);
      }
    } catch (error) {
      let toastMessage =
        error instanceof Error ? error.message : lang.errors.default;
      const toastStatus = 'error';
      if (error instanceof AxiosError && error.response) {
        const errorMessages = error.response.data.message;
        if (Array.isArray(errorMessages)) {
          errorMessages.forEach((msg: string) => {
            toastMessage += msg + ' \n';
          });
        } else {
          toastMessage = errorMessages;
        }
      }
      addToast(toastMessage, toastStatus);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* User displayName */}
      <CustomTextInput
        name="displayName"
        label="Name"
        helperMessage=""
        value={formik.values.displayName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isMandatory={true}
      />
      {formik.touched.displayName && formik.errors.displayName && (
        <CustomErrorText errorMessage={formik.errors.displayName as string} />
      )}
      {/* User email */}
      <CustomEmailInput
        name="email"
        label="Email address"
        helperMessage=""
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        isMandatory={true}
      />
      {formik.touched.email && formik.errors.email && (
        <CustomErrorText errorMessage={formik.errors.email as string} />
      )}

      {/* isActive  */}
      {!isAdmin && (initialValues as UpdateUserDto).id && (
        <CustomSwitchInput
          name="isActive"
          label="Active user"
          value={
            (formik as FormikProps<CreateUserDto | UpdateUserDto>).values
              .isActive
          }
          onChange={(e) =>
            isUpdate
              ? handleIsActiveChange(e as ChangeEvent<HTMLInputElement>)
              : formik.handleChange(e)
          }
          onBlur={formik.handleBlur}
        />
      )}

      {/* Submit */}
      <Center>
        {' '}
        <GenericButton
          type="submit"
          text="Save"
          onClick={formik.handleSubmit}
        />
      </Center>
    </form>
  );
}
