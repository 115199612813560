import React from 'react';
import { Card, CardBody, CardHeader, Heading } from '@chakra-ui/react';

import { EngagementDto } from '../../../api/dto/engagement.dto';
import { updateEngagementHideDemographics } from '../../../api/functions/engagements.functions';
import { CustomToast } from '../../../components/CustomToast';
import { CustomCheckBoxInput } from '../../../components/Form/CustomCheckBoxInput';
import lang from '../../../utils/lang';

type EngagementSettingsCardProps = {
  engagement: EngagementDto;
  fetchEngagement: () => void;
  isDisabled?: boolean;
};

export const EngagementSettingsCard = ({
  engagement,
  fetchEngagement,
  isDisabled = false,
}: EngagementSettingsCardProps) => {
  const { addToast } = CustomToast();

  const handleUpdateHideDemographics = async () => {
    try {
      await updateEngagementHideDemographics(
        !engagement.hideDemographics,
        engagement.id
      );
      const toastMessage = engagement.hideDemographics
        ? `Demographics are now shown for '${engagement.name}'`
        : `Demographics are now hidden for '${engagement.name}'`;
      const toastStatus = 'success';
      addToast(toastMessage, toastStatus);
      fetchEngagement();
    } catch (error) {
      console.error(error);
      const toastMessage =
        error instanceof Error ? error.message : lang.errors.default;
      const toastStatus = 'error';
      addToast(toastMessage, toastStatus);
    }
  };

  return (
    <Card variant="elevated" padding={3}>
      <CardHeader>
        <Heading as="h2" fontSize={20} lineHeight={'20px'} textAlign="left">
          Settings
        </Heading>
      </CardHeader>

      <CardBody justifyContent="start" padding={0}>
        <CustomCheckBoxInput
          name="toggleDemographics"
          label={
            engagement.hideDemographics
              ? lang.engagement.single.showDemographics
              : lang.engagement.single.hideDemographics
          }
          value={engagement.hideDemographics}
          onChange={handleUpdateHideDemographics}
          isMandatory={false}
          isDisabled={isDisabled}
        />
      </CardBody>
    </Card>
  );
};
