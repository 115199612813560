import React from 'react';
import DatePicker from 'react-datepicker';
import { CalendarIcon } from '@chakra-ui/icons';
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { subDays } from 'date-fns';

import 'react-datepicker/dist/react-datepicker.css';

export const CustomDateInput = ({
  name,
  label,
  placeholder,
  value,
  minDate,
  isMandatory,
  onChange,
  onBlur,
}: {
  name: string;
  label: string;
  placeholder: string;
  minDate: Date | undefined;
  value: Date | undefined;
  isMandatory: boolean;
  onChange: (e: React.ChangeEvent<unknown>) => void;
  onBlur: (e: React.FocusEvent<unknown, Element>) => void;
}) => {
  return (
    <FormControl padding={1}>
      <FormLabel display="flex" alignItems="center">
        {label}
      </FormLabel>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <CalendarIcon marginRight={1} color="keyops.blue" />
        </InputLeftElement>
        <Input
          as={DatePicker}
          isClearable
          placeholderText={placeholder}
          dateFormat="dd/MM/yyyy"
          id={name}
          name={name}
          selected={value}
          minDate={minDate ? subDays(minDate, 0) : undefined}
          onChange={onChange}
          css={{
            width: `${value ? '100%' : placeholder.length * 8 + 'px'}`,
          }}
          wrapperClassName="datepicker"
          // Need to add a portal id to ensure subssequent form elements don't layer on top of the date picker.
          portalId="datePickerPortal"
        />
      </InputGroup>
    </FormControl>
  );
};
