import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';

type Crumbs = {
  text: string;
  link: string;
};

export const CustomBreadcrumb = ({ list }: { list: Crumbs[] }) => {
  const breadcrumb = list.map((crumb, index) => {
    return (
      <BreadcrumbItem key={index} isCurrentPage={index === list.length - 1}>
        <BreadcrumbLink href={crumb.link}>{crumb.text}</BreadcrumbLink>
      </BreadcrumbItem>
    );
  });
  return (
    <Breadcrumb
      ml={5}
      mb={3}
      spacing={3}
      separator={<ChevronRightIcon color="gray.500" />}
    >
      {breadcrumb}
    </Breadcrumb>
  );
};

type SingleCrumb = {
  link: string;
  text?: string;
};
export const BackBreadcrumb = ({ link, text = 'Back' }: SingleCrumb) => {
  return (
    <Breadcrumb
      ml={5}
      mb={3}
      spacing={3}
      separator={<ChevronRightIcon color="gray.500" />}
    >
      <ChevronLeftIcon color="keyops.blue" mr={3} />
      <BreadcrumbItem color="keyops.blue">
        <BreadcrumbLink href={link}>{text}</BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  );
};
