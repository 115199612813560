import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  Divider,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  ComparableQuestions,
  Question,
  QuestionAnnotation,
} from '@keyops-cep/api-client';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';

import lang from '../../../utils/lang';

type QuestionsComparisonCardProps = {
  comparableQuestions: ComparableQuestions;
  currentEngagementName: string;
  comparableEngagementName: string;
  prevKey: number;
};
export const QuestionsComparisonCard = ({
  comparableQuestions,
  currentEngagementName,
  comparableEngagementName,
  prevKey,
}: QuestionsComparisonCardProps) => {
  // Comparative report: we only display the conclusion sentence by default and have to click on "Read more" to see the full report
  const summarizedReport =
    comparableQuestions?.report?.split('**In summary**:')[1] ?? '';
  const fullReport =
    comparableQuestions?.report?.split('**In summary**:')[0] ??
    comparableQuestions?.report;

  return (
    <Card key={prevKey} variant="elevated" p={3} mb={3}>
      <Accordion allowToggle border={'none'} p={0}>
        <AccordionItem border={'none'}>
          <AccordionButton justifyContent={'space-between'}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              width={'100%'}
              spacing={7}
            >
              {comparableQuestions.questions.map(
                (question: Question, key: number) => (
                  <Stack direction={'column'} key={key} w={'50%'}>
                    <Heading
                      as={'h4'}
                      fontSize={12}
                      textAlign="left"
                      color="gray.700"
                      textTransform={'capitalize'}
                      fontWeight={400}
                    >
                      {key === 0
                        ? currentEngagementName
                        : comparableEngagementName}
                    </Heading>
                    <Heading as={'h3'} fontSize={16} textAlign="left">
                      {`Question ${question.position + 1}`}
                    </Heading>
                  </Stack>
                )
              )}
            </Stack>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={4}>
            {/* Question label */}
            <Stack direction={'row'} spacing={3}>
              {comparableQuestions.questions.map(
                (question: Question, key: number) => (
                  <Stack direction={'column'} key={key} w={'50%'}>
                    <Text fontSize={16} textAlign="left" h={'max-content'}>
                      {question.label}
                    </Text>
                  </Stack>
                )
              )}
            </Stack>

            {/* Question type */}
            <Stack direction={'row'} spacing={3}>
              {comparableQuestions.questions.map(
                (question: Question, key: number) => (
                  <Stack direction={'column'} key={key} w={'50%'}>
                    <Heading as={'h3'} fontSize={16} textAlign="left" pt={3}>
                      {lang.engagement.single.comparisonAnalysis.questions.type}
                    </Heading>

                    <Text fontSize={16} textAlign="left">
                      {question.type}
                    </Text>
                  </Stack>
                )
              )}
            </Stack>

            {/* Question themes */}
            <Stack direction={'row'} spacing={3}>
              {comparableQuestions.questions.map(
                (question: Question, key: number) => (
                  <Stack direction={'column'} key={key} w={'50%'}>
                    <Heading as={'h3'} fontSize={16} textAlign="left" pt={3}>
                      {
                        lang.engagement.single.comparisonAnalysis.questions
                          .themes
                      }
                    </Heading>

                    <Text fontSize={16} textAlign="left">
                      {question.themes}
                    </Text>
                  </Stack>
                )
              )}
            </Stack>

            {/* QuestionAnnotation */}
            <Stack direction={'row'} spacing={3}>
              {comparableQuestions.questions.map(
                (question: Question, key: number) =>
                  question?.questionAnnotations?.length > 0 && (
                    <Stack direction={'column'} key={key} w={'50%'}>
                      <Heading as={'h3'} fontSize={16} textAlign="left" pt={3}>
                        {
                          lang.engagement.single.comparisonAnalysis.questions
                            .annotations
                        }
                      </Heading>

                      {question.questionAnnotations.map(
                        (questionAnnotation: QuestionAnnotation, key) => (
                          <Text key={key} fontSize={16} textAlign="left">
                            {questionAnnotation.name}:{' '}
                            {questionAnnotation.value} (
                            {questionAnnotation.description})
                          </Text>
                        )
                      )}
                    </Stack>
                  )
              )}
            </Stack>

            <Divider mt={5} mb={5} />
            {/* AI remarks aboout the questions pair */}
            {comparableQuestions?.notes && (
              <Stack direction={'row'} spacing={3}>
                <Stack direction={'column'} w={'100%'}>
                  <Heading as={'h3'} fontSize={16} textAlign="left">
                    {lang.engagement.single.comparisonAnalysis.questions.notes}
                  </Heading>

                  <Text fontSize={16} textAlign="left">
                    {comparableQuestions.notes}
                  </Text>
                </Stack>
              </Stack>
            )}
            {/* AI report aboout the questions pair */}
            {comparableQuestions?.report && (
              <Stack direction={'row'} spacing={3}>
                <Stack direction={'column'} w={'100%'}>
                  <Accordion allowToggle>
                    <AccordionItem border={'none'}>
                      <Heading
                        as={'h3'}
                        fontSize={16}
                        textAlign="left"
                        pt={3}
                        pb={3}
                      >
                        {
                          lang.engagement.single.comparisonAnalysis.questions
                            .comparisonReport
                        }
                      </Heading>
                      <MDEditor.Markdown
                        style={{ backgroundColor: 'transparent' }}
                        source={summarizedReport}
                        rehypePlugins={[rehypeSanitize]}
                      />

                      <AccordionButton
                        justifyContent={'flex-start'}
                        cursor={'default'}
                        _hover={{
                          backgroundColor: 'none',
                        }}
                        color={'keyops.blue'}
                        p={'8px 0 0 0'}
                        m={0}
                        fontSize={'14px'}
                        fontWeight={'400'}
                      >
                        Show more <AccordionIcon />
                      </AccordionButton>

                      <AccordionPanel pb={4}>
                        <MDEditor.Markdown
                          style={{ backgroundColor: 'transparent' }}
                          source={fullReport}
                          rehypePlugins={[rehypeSanitize]}
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </Stack>
              </Stack>
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Card>
  );
};
