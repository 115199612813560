import * as React from 'react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  chakra,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import { buildUsersTableColumns, UsersTableHead } from '..';

interface UsersTableProps {
  data: UsersTableHead[];
  handleUserAction: () => void;
  handleResendInvite: () => void;
}

export const UsersTable = ({
  data,
  handleUserAction,
  handleResendInvite,
}: UsersTableProps) => {
  // Build the table logic
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const table = useReactTable<UsersTableHead>({
    columns: buildUsersTableColumns(handleUserAction, handleResendInvite),
    data: data as UsersTableHead[],
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <TableContainer
      padding={5}
      margin={5}
      borderRadius="lg"
      borderStyle="solid"
      borderWidth="thin"
      borderColor="gray.200"
    >
      <Table variant="unstyled">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    cursor={'pointer'}
                    fontSize={'sm'}
                    textTransform={'capitalize'}
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}

                    <chakra.span pl="4">
                      {header.column.getIsSorted() ? (
                        header.column.getIsSorted() === 'desc' ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <Td key={row.id + cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
