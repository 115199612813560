import { AxiosResponse } from 'axios';

import { METHOD_TYPES } from '../api-constants';
import { performApiRequest } from '../api-request-builder';
import {
  RespondentDemographicResponseDto,
  UpdateRespondentDemographicDto,
} from '../dto/respondent-demographic.dto';
import { UPDATE_RESPONDENT_DEMOGRAPHIC } from '../routes/respondent-demographic.routes';

export const updateRespondentDemographic = async (
  respondentDemographicId: string,
  respondentDemographic: UpdateRespondentDemographicDto
): Promise<
  AxiosResponse<RespondentDemographicResponseDto, undefined> | undefined
> => {
  let response;
  try {
    response = await performApiRequest<UpdateRespondentDemographicDto>({
      method: METHOD_TYPES.PATCH,
      endpoint: UPDATE_RESPONDENT_DEMOGRAPHIC.replace(
        ':id',
        respondentDemographicId
      ),
      params: {},
      data: respondentDemographic,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
