export const HEADERS: Record<string, string | null> = {
  'Content-Type': 'application/json',
};

export const METHOD_TYPES: Record<string, string> = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};
