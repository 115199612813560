import React from 'react';
import { FiEye, FiRefreshCw } from 'react-icons/fi';
import { NavigateFunction } from 'react-router-dom';
import { Stack } from '@chakra-ui/react';

import { EngagementDto } from '../../../api/dto/engagement.dto';
import { TenantResponseDto } from '../../../api/dto/tenant.dto';
import { apiClient } from '../../../api/swagger-codegen-api-config';
import { CustomMenuButton } from '../../../components/CustomMenuButton';
import { CustomToast } from '../../../components/CustomToast';
import GenericButton from '../../../components/GenericButton';
import { CUSTOMER_UI_ENGAGEMENT_DETAILS } from '../../../utils/external-routes';
import { navigateToCustomerUi } from '../../../utils/functions/navigate-to-customer-ui';
import {
  TENANTED_ENGAGEMENT_ANALYSIS_PAGE,
  TENANTED_ENGAGEMENT_RESPONSES_PAGE,
} from '../../../utils/internal-routes';
import lang from '../../../utils/lang';

type EngagementActionButtonsProps = {
  engagement: EngagementDto;
  tenant: TenantResponseDto;
  isLoading: boolean;
  handleRefreshSurvey: () => void;
  fetchEngagementFiles: () => void;
  navigate: NavigateFunction;
};

export const EngagementActionButtons = ({
  engagement,
  tenant,
  isLoading,
  handleRefreshSurvey,
  fetchEngagementFiles,
  navigate,
}: EngagementActionButtonsProps) => {
  const { addToast } = CustomToast();
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const handleFileUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      await apiClient.engagementFilesApi.engagementFileControllerUploadForm(
        file.name,
        engagement.id,
        file
      );
      fetchEngagementFiles();
      addToast(`Successfully uploaded file ${file.name}`, 'success');
    }
  };

  const menuItems = [
    {
      text: lang.engagement.single.actions.editCommentary,

      action: () =>
        navigate(
          TENANTED_ENGAGEMENT_ANALYSIS_PAGE.replace(
            ':tenantId',
            tenant.id
          ).replace(':engagementId', engagement.id)
        ),
    },
    {
      text: lang.engagement.single.actions.editDemo,
      action: () =>
        navigate(
          TENANTED_ENGAGEMENT_RESPONSES_PAGE.replace(
            ':tenantId',
            tenant.id
          ).replace(':engagementId', engagement.id)
        ),
    },
    // TODO: uncomment when the delete feature is done
    // {
    //   text: lang.engagement.single.delete,
    //   action: () => console.log('delete'),
    // },
    {
      text: lang.engagement.single.actions.uploadFile,
      action: handleFileUploadClick, // Updated action for file upload
    },
  ];

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <Stack spacing={3} direction="row" justify="right">
        ({/** TODO look into rendering this as an iFrame */})
        <GenericButton
          type="button"
          text="Refresh Survey"
          background="white"
          leftIcon={<FiRefreshCw aria-label="preview-icon" />}
          margin={0}
          isLoading={isLoading}
          onClick={handleRefreshSurvey}
        />
        <GenericButton
          type="button"
          text={lang.engagement.single.preview}
          leftIcon={<FiEye aria-label="preview-icon" />}
          background="white"
          isDisabled={isLoading}
          onClick={() => {
            navigateToCustomerUi(
              CUSTOMER_UI_ENGAGEMENT_DETAILS.replace(
                ':engagementId',
                engagement.id
              )
            );
          }}
          margin={0}
        />
        <CustomMenuButton
          title="Actions"
          menuItems={menuItems}
          isLoading={isLoading}
        />
      </Stack>
    </>
  );
};
