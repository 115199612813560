import { AxiosResponse, AxiosStatic } from "axios";

export function getAxiosInterceptor(
  setNewTokens: () => Promise<AxiosResponse | undefined>,
  removeTokens: () => void,
  LOGIN: string,
  axios: AxiosStatic
) {
  return {
    responseHandler: (response: AxiosResponse) => response,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errorHandler: async (error: any) => {
      if (!error) {
        return;
      }
      const originalRequest = error.config;
      //if it's a security error and we weren't retrying and we weren't refreshing
      //get new tokens and try again
      if (
        (error.response?.status === 401 || error.response?.status === 403) &&
        originalRequest &&
        originalRequest.url &&
        !originalRequest.url.includes("/auth/") &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const response = await fetchNewTokens(
          setNewTokens,
          removeTokens,
          LOGIN
        );

        if (response?.status === 201) {
          originalRequest.headers["Authorization"] =
            "Bearer " + response?.data?.accessToken;

          // Retry the original request with the new tokens
          return axios(originalRequest);
        }
        return Promise.reject(response);
      }
      const objectToRejectWith =
        error.response?.data ?? error.response ?? error;
      return Promise.reject(objectToRejectWith);
    },
  };
}

const fetchNewTokens = async (
  setNewTokens: () => Promise<AxiosResponse | undefined>,
  removeTokens: () => void,
  LOGIN: string
) => {
  try {
    return await setNewTokens();
  } catch (caughtError) {
    if (caughtError.statusCode === 401 || caughtError.statusCode === 403) {
      removeTokens();
      window.location.href = LOGIN;
    } else {
      //not a security exception, so let the application code handle it.
      throw caughtError;
    }
  }
};
