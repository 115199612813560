import React from 'react';
import {
  createSearchParams,
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import Loading from '../../components/Loading';
import { LOGIN } from '../../utils/internal-routes';

import useAuth from './useAuth';

const PrivateRoutes: React.FunctionComponent = () => {
  const { isLoggedInFromApi, loading } = useAuth();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  if (loading) {
    //loading during axios call for auth check
    return <Loading />;
  }

  return isLoggedInFromApi ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: LOGIN,
        search: `?${createSearchParams(searchParams)}`,
      }}
      state={Object.assign({}, location.state)}
      replace
    />
  );
};

export default PrivateRoutes;
