import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { WarningIcon } from '@chakra-ui/icons';
import { useToast } from '@chakra-ui/react';

export type ToastStatus =
  | 'info'
  | 'warning'
  | 'success'
  | 'error'
  | 'loading'
  | undefined;

export const CustomToast = () => {
  const customToast = useToast();

  const addToast = (toastMessage: string, toastStatus: ToastStatus) => {
    customToast({
      description: toastMessage,
      status: toastStatus,
      duration: 5000,
      position: 'top-right',
      icon: toastStatus === 'success' ? <FaCheckCircle /> : <WarningIcon />,
      variant: 'solid',
    });
  };

  return { addToast };
};
