import { METHOD_TYPES } from '../api-constants';
import { performApiRequest } from '../api-request-builder';
import { CreateUserDto, UpdateUserDto } from '../dto/user.dto';
import {
  GET_USER_ENDPOINT,
  GET_USERS_ENDPOINT,
  POST_USER_ENDPOINT,
  RESEND_INVITE_ENDPOINT,
  UPDATE_USER_ENDPOINT,
  UPDATE_USER_IS_ACTIVE_ENDPOINT,
} from '../routes/user.routes';

export const getUsersCall = async () => {
  let response;
  try {
    response = await performApiRequest({
      method: METHOD_TYPES.GET,
      endpoint: GET_USERS_ENDPOINT,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }

  return response;
};

export const getUserCall = async (userId: string) => {
  let response;
  try {
    response = await performApiRequest({
      method: METHOD_TYPES.GET,
      endpoint: GET_USER_ENDPOINT.replace(':userId', userId),
    });
  } catch (error) {
    console.error(error);
    throw error;
  }

  return response;
};

export const createUserCall = async (user: CreateUserDto) => {
  let response;
  try {
    response = await performApiRequest({
      method: METHOD_TYPES.POST,
      endpoint: POST_USER_ENDPOINT,
      params: {},
      data: user,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateUserCall = async (user: UpdateUserDto) => {
  let response;
  try {
    response = await performApiRequest({
      method: METHOD_TYPES.POST,
      endpoint: UPDATE_USER_ENDPOINT.replace(':userId', user.id),
      params: {},
      data: user,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateUserIsActiveCall = async (
  user: Pick<UpdateUserDto, 'id' | 'isActive'>
) => {
  let response;
  try {
    response = await performApiRequest({
      method: METHOD_TYPES.POST,
      endpoint: UPDATE_USER_IS_ACTIVE_ENDPOINT.replace(':userId', user.id),
      params: {},
      data: { isActive: user.isActive },
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const resendInviteCall = async (userId: string) => {
  let response;
  try {
    response = await performApiRequest({
      method: METHOD_TYPES.POST,
      endpoint: RESEND_INVITE_ENDPOINT.replace(':userId', userId),
      params: {},
      data: {},
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
