import { createContext } from 'react';

type AuthContextTypes = {
  isLoggedInFromApi: boolean; //for refresh token purpose's
  loading: boolean;
  handleGoogleLogin: (idToken: string | undefined) => void;
  handleGoogleLogout: () => void;
};

const AuthContext = createContext<AuthContextTypes>({
  isLoggedInFromApi: false,
  loading: false,
  handleGoogleLogin: () => undefined,
  handleGoogleLogout: () => undefined,
});

export default AuthContext;
