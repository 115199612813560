import React from 'react';
import { FormControl, FormLabel, Switch } from '@chakra-ui/react';

export const CustomSwitchInput = ({
  name,
  label,
  value,
  onChange,
  onBlur,
}: {
  name: string;
  label: string;
  value: boolean;
  onChange: (e: React.ChangeEvent<unknown>) => void;
  onBlur: (e: React.FocusEvent<unknown, Element>) => void;
}) => {
  return (
    <FormControl padding={3}>
      <FormLabel htmlFor={name} margin={0}>
        {label.charAt(0).toUpperCase() + label.slice(1)}
      </FormLabel>
      <Switch
        id={name}
        name={name}
        isChecked={value}
        onChange={onChange}
        onBlur={onBlur}
        paddingRight={3}
      />
    </FormControl>
  );
};
