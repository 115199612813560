import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AuthProvider from './HOCs/auth/AuthProvider';
import PrivateRoutes from './HOCs/auth/PrivateRoutes';
import PublicRoutes from './HOCs/auth/PublicRoutes';
import NavBar from './HOCs/navbar';
import Admins from './pages/Admins';
import Analysis from './pages/Analysis';
import Engagement from './pages/Engagement';
import Engagements from './pages/Engagements';
import { EngagementsComparison } from './pages/EngagementsComparison';
import Login from './pages/Login';
import Logout from './pages/Login/Logout';
import NotFound from './pages/NotFound';
import Responses from './pages/Responses';
import Tenants from './pages/Tenants';
import { Users } from './pages/Users';
import * as ROUTES from './utils/internal-routes';

import './App.css';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route path={ROUTES.ROOT} element={<Login />} />
            <Route path={ROUTES.LOGIN} element={<Login />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route element={<NavBar />}>
              <Route path={ROUTES.ADMINS_PAGE} element={<Admins />} />
              <Route path={ROUTES.TENANTED_USERS_PAGE} element={<Users />} />
              <Route path={ROUTES.ENGAGEMENTS_PAGE} element={<Engagements />} />
              <Route
                path={ROUTES.TENANTED_ENGAGEMENTS_PAGE}
                element={<Engagements />}
              />
              <Route
                path={ROUTES.TENANTED_ENGAGEMENT_SINGLE_PAGE}
                element={<Engagement />}
              />
              <Route
                path={ROUTES.TENANTED_ENGAGEMENT_ANALYSIS_PAGE}
                element={<Analysis />}
              />
              <Route
                path={ROUTES.TENANTED_ENGAGEMENT_RESPONSES_PAGE}
                element={<Responses />}
              />
              <Route path={ROUTES.TENANTS_PAGE} element={<Tenants />} />
              <Route path={ROUTES.TENANTED_USERS_PAGE} element={<Users />} />
              <Route
                path={ROUTES.ENGAGEMENTS_COMPARISON_PAGE}
                element={<EngagementsComparison />}
              />
            </Route>
            <Route path={ROUTES.LOGOUT} element={<Logout />} />
          </Route>
          <Route path={'*'} element={<NotFound />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
