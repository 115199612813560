import {
  CUSTOMER_UI_ADMIN_REDIRECT,
  CUSTOMER_UI_ROUTE,
} from '../external-routes';

export async function navigateToCustomerUi(route: string) {
  window.open(
    `${CUSTOMER_UI_ROUTE}${CUSTOMER_UI_ADMIN_REDIRECT.replace(
      ':redirectUrl',
      encodeURIComponent(route)
    )}`,
    '_blank'
  );
}
