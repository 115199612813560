/* tslint:disable */
/* eslint-disable */
/**
 * Keyops CEP
 * Customer Engagement Platform API documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface UpdateTenantDto
 */
export interface UpdateTenantDto {
    /**
     * The name of the tenant
     * @type {string}
     * @memberof UpdateTenantDto
     */
    name: string;
    /**
     * The country the tenant is in
     * @type {string}
     * @memberof UpdateTenantDto
     */
    country: UpdateTenantDtoCountryEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateTenantDtoCountryEnum {
    US = 'US',
    CAN = 'CAN'
}

