import { AxiosResponse } from 'axios';

import { METHOD_TYPES } from '../api-constants';
import { performApiRequest } from '../api-request-builder';
import { RespondentDemographicResponseDto } from '../dto/respondent-demographic.dto';
import { GET_RESPONSE_RESPONDENT_DEMOGRAPHICS } from '../routes/response.routes';

export const getResponseRespondentDemographics = async (
  responseId: string
): Promise<AxiosResponse<RespondentDemographicResponseDto[]> | undefined> => {
  let response;
  try {
    response = await performApiRequest({
      method: METHOD_TYPES.GET,
      endpoint: GET_RESPONSE_RESPONDENT_DEMOGRAPHICS.replace(
        ':responseId',
        responseId
      ),
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
