// External Dependencies
import React from 'react';
import { Center, CircularProgress } from '@chakra-ui/react';

const Loading: React.FC = () => {
  return (
    <Center h={'100vh'}>
      <CircularProgress
        aria-label="progress"
        isIndeterminate
        size="120px"
        color="keyops.blue"
        thickness={8}
      />
    </Center>
  );
};

export default Loading;
