import React from 'react';
import { FiTrash } from 'react-icons/fi';
import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { EngagementFile } from '@keyops-cep/api-client';

import { apiClient } from '../../../api/swagger-codegen-api-config';
import { CustomToast } from '../../../components/CustomToast';
import lang from '../../../utils/lang';

function formatFileName(fileName: string, charLimit: number) {
  // Check if the file name length is within the limit
  if (fileName.length <= charLimit) {
    return fileName;
  }

  const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
  const baseName = fileName.substring(0, fileName.lastIndexOf('.'));

  // Calculate the maximum length for the start part
  const maxStartPartLength = charLimit - fileExtension.length - 4 - 3; // Subtracting for ellipsis, space, and last 4 chars

  if (baseName.length > maxStartPartLength) {
    const startPart = baseName.substring(0, maxStartPartLength);
    const endPart = baseName.slice(-4);
    return `${startPart}... ${endPart}${fileExtension}`;
  }

  return fileName; // Return the original fileName if it's shorter than the char limit
}

const EngagementFileRow = ({
  engagementFile,
  fetchEngagementFiles,
}: {
  engagementFile: EngagementFile;
  fetchEngagementFiles: () => void;
}) => {
  const { addToast } = CustomToast();

  const handleDelete = async (engagementFileId: string) => {
    const deleteEngagementFileResponse =
      await apiClient.engagementFilesApi.engagementFileControllerDelete(
        engagementFileId
      );
    if (deleteEngagementFileResponse) {
      addToast(
        `EngagementFile ${engagementFile.fileName} successfully deleted`,
        'success'
      );
    }
    fetchEngagementFiles();
  };

  return (
    <Stack
      justifyContent="space-between"
      direction="row"
      alignItems={'center'}
      mb="8px"
    >
      <Tooltip label={engagementFile.fileName} placement="top">
        <Text fontSize={'14px'}>
          {formatFileName(engagementFile.fileName, 45)}
        </Text>
      </Tooltip>

      <FiTrash onClick={() => handleDelete(engagementFile.id)} />
    </Stack>
  );
};

export const EngagementFileCard = ({
  engagementFiles,
  fetchEngagementFiles,
}: {
  engagementFiles: EngagementFile[];
  fetchEngagementFiles: () => void;
}) => {
  return (
    <Card
      variant="elevated"
      size="xs"
      maxH="4xs"
      height="-webkit-fit-content"
      width="xs"
      padding={3}
    >
      <CardHeader>
        <Heading
          as="h2"
          fontSize={20}
          lineHeight={'20px'}
          textAlign="left"
          pb={'8px'}
        >
          {lang.engagement.single.files.header}
        </Heading>
        <Text fontSize={'12px'} pb={'16px'}>
          {lang.engagement.single.files.description}
        </Text>
      </CardHeader>

      <CardBody textAlign="center">
        {engagementFiles.map((engagementFile) => {
          return (
            <EngagementFileRow
              key={engagementFile.id}
              engagementFile={engagementFile}
              fetchEngagementFiles={fetchEngagementFiles}
            />
          );
        })}
      </CardBody>
    </Card>
  );
};
