import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TenantResponseDto } from '../../api/dto/tenant.dto';
import {
  getTenantCall,
  getTenantsCall,
} from '../../api/functions/tenants.functions';
import { CustomToast } from '../../components/CustomToast';
import lang from '../../utils/lang';

type UseTenantsType = TenantResponseDto[] | [];

export const useTenants = (): UseTenantsType => {
  const [tenants, setTenants] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const response = await getTenantsCall();
      if (response) {
        setTenants(response.data);
      }
    }
    fetchData().catch(console.error);
  }, []);

  return tenants;
};

type UseTenantType = TenantResponseDto;

export const useTenant = (): UseTenantType => {
  const { tenantId } = useParams();
  const [tenant, setTenant] = useState<TenantResponseDto | undefined>(
    undefined
  );
  const { addToast } = CustomToast();
  useEffect(() => {
    async function fetchData() {
      try {
        if (!tenantId) return;
        const response = await getTenantCall(tenantId);
        if (response) {
          setTenant(response.data);
        }
      } catch (error) {
        console.error(error);
        const toastMessage =
          error instanceof Error ? error.message : lang.errors.default;
        const toastStatus = 'error';
        addToast(toastMessage, toastStatus);
      }
    }
    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // TODO: check if this typing is dangerous
  return tenant as unknown as TenantResponseDto;
};
