import React, { useCallback, useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { FaRegFlag } from 'react-icons/fa6';
import { FiUser } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Heading,
  HStack,
  IconButton,
  Stack,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { FeatureFlag } from '@keyops-cep/api-client';
import { createColumnHelper } from '@tanstack/react-table';
import { AxiosResponse } from 'axios';

import {
  CreateTenantDto,
  TenantResponseDto,
  UpdateTenantDto,
} from '../../api/dto/tenant.dto';
import {
  createTenantCall,
  getTenantCall,
  getTenantsCall,
  updateTenantCall,
} from '../../api/functions/tenants.functions';
import { apiClient } from '../../api/swagger-codegen-api-config';
import { CustomToast } from '../../components/CustomToast';
import GenericButton from '../../components/GenericButton';
import Loading from '../../components/Loading';
import { dateFormatter } from '../../utils/functions/common-utils';
import {
  ENGAGEMENTS_PAGE,
  TENANTED_ENGAGEMENTS_PAGE,
  TENANTED_USERS_PAGE,
} from '../../utils/internal-routes';
import lang from '../../utils/lang';

import { FeatureFlagModal } from './components/featureFlagModal';
import { IsActiveTenantSwitch } from './components/isActiveTenantSwitch';
import { TenantModal } from './components/tenantModal';
import { TenantsTable } from './components/tenantsTable';

// Build table logic
export type TenantsTableHead = {
  id: string;
  name: string;
  country: string;
  isActive: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  actions: string;
};

export const buildTenantTableColumns = (
  navigate: ReturnType<typeof useNavigate>,
  handleTenantAction: (tenantId: string) => void,
  handleFeatureFlagAction: (tenantId: string) => void
) => {
  const columnHelper = createColumnHelper<TenantsTableHead>();
  const columns = [
    columnHelper.accessor('name', {
      cell: (info) => {
        const tenantId = info.row.original.id;
        return (
          <Button
            variant="link"
            onClick={() => {
              navigate(
                TENANTED_ENGAGEMENTS_PAGE.replace(':tenantId', tenantId)
              );
            }}
          >
            {info.getValue()}
          </Button>
        );
      },
      header: lang.tenant.all.headers.clientName,
    }),
    columnHelper.accessor('id', {
      cell: (info) => info.getValue(),
      header: lang.tenant.all.headers.projectID,
    }),
    columnHelper.accessor('country', {
      cell: (info) => info.getValue(),
      header: lang.tenant.all.headers.country,
    }),
    columnHelper.accessor('isActive', {
      cell: (info) => (
        <IsActiveTenantSwitch
          id={info.row.original.id}
          name={info.row.original.name}
          value={info.getValue()}
        />
      ),
      header: lang.tenant.all.headers.active,
    }),
    columnHelper.accessor('createdAt', {
      cell: (info) => dateFormatter(info.getValue()?.getTime()),
      header: lang.tenant.all.headers.createdAt,
    }),
    columnHelper.accessor('updatedAt', {
      cell: (info) => dateFormatter(info.getValue()?.getTime()),
      header: lang.tenant.all.headers.updatedAt,
    }),
    columnHelper.accessor('actions', {
      header: lang.tenant.all.headers.actions.label,
      cell: (info) => {
        const tenantId = info.row.original.id;

        return (
          <HStack spacing={3}>
            <Tooltip
              label={lang.tenant.all.headers.actions.viewAllUsers}
              fontSize="md"
            >
              <IconButton
                aria-label={lang.tenant.all.headers.actions.viewAllUsers}
                icon={<FiUser />}
                onClick={() => {
                  navigate(TENANTED_USERS_PAGE.replace(':tenantId', tenantId));
                }}
              />
            </Tooltip>

            <Tooltip label={lang.tenant.all.headers.actions.edit} fontSize="md">
              <IconButton
                aria-label={lang.tenant.all.headers.actions.edit}
                icon={<FaEdit />}
                onClick={() => handleTenantAction(info.row.original.id)}
              />
            </Tooltip>

            <Tooltip
              label={lang.tenant.all.headers.actions.editFeatureFlags}
              fontSize="md"
            >
              <IconButton
                aria-label={lang.tenant.all.headers.actions.editFeatureFlags}
                icon={<FaRegFlag />}
                onClick={() => handleFeatureFlagAction(info.row.original.id)}
              />
            </Tooltip>
          </HStack>
        );
      },
    }),
  ];

  return columns;
};

const buildTenantTableData = (tenants: TenantResponseDto[]) => {
  const data: TenantsTableHead[] = [];
  tenants.forEach((tenant) =>
    data.push({
      id: tenant.id,
      name: tenant.name,
      country: tenant.country,
      isActive: tenant.isActive,
      createdAt: tenant.createdAt ? new Date(tenant.createdAt) : undefined,
      updatedAt: tenant.updatedAt ? new Date(tenant.updatedAt) : undefined,
      actions: '',
    })
  );

  return data;
};

const Tenants: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { addToast } = CustomToast();
  // Modal handling
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isFeatureFlagsOpen,
    onOpen: onFeatureFlagsOpen,
    onClose: onFeatureFlagsClose,
  } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [tenants, setTenants] = useState([] as TenantResponseDto[]);
  const [tenant, setTenant] = useState(
    undefined as TenantResponseDto | undefined
  );
  const [featureFlags, setFeatureFlags] = useState([] as FeatureFlag[]);
  const tenantsData = buildTenantTableData(tenants);

  // TODO maybe we should get the tenant obj from the previously fetched "tenants" array
  // instead of making another API call each time an action is selected
  const fetchTenant = useCallback(
    async (tenantId: string): Promise<TenantResponseDto | undefined> => {
      try {
        const response = await getTenantCall(tenantId);
        if (response) return response.data;
      } catch (error) {
        console.error(error);
      }
    },
    []
  );

  const fetchTenants = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const response = await getTenantsCall();
      if (response) {
        setTenants(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(`fetchTenants` + error);
      setIsLoading(false);
    }
  };

  const fetchFeatureFlags = async (tenantId: string) => {
    const fetchedTenant = await fetchTenant(tenantId);
    if (!fetchedTenant)
      throw new Error(`Could not find tenant with ID ${tenantId}`);
    const response = await apiClient.tenantsApi.tenantControllerFeatureFlags(
      fetchedTenant.id
    );
    setFeatureFlags(response.data);
    setTenant(fetchedTenant as TenantResponseDto);
  };

  useEffect(() => {
    fetchTenants();
  }, [tenant]);

  const handleEditTenantAction = async (tenantId?: string) => {
    if (tenantId) {
      const tenant = await fetchTenant(tenantId);
      setTenant(tenant as TenantResponseDto);
    } else {
      setTenant(undefined as undefined);
    }
    onEditOpen();
  };

  const handleFeatureFlagAction = async (tenantId: string) => {
    await fetchFeatureFlags(tenantId);
    onFeatureFlagsOpen();
  };

  // Will call update or create tenant endoint
  const onFormSubmit = async (values: CreateTenantDto | UpdateTenantDto) => {
    let response: AxiosResponse | undefined;
    try {
      //  Update case
      if ('id' in values && values.id) {
        response = await updateTenantCall(values.id, {
          name: values.name,
          country: values.country,
        } as UpdateTenantDto);
        //  Creation case
      } else {
        response = await createTenantCall(values);
      }
      if (response?.status === 201) {
        await fetchTenants();
        onEditClose();

        const toastMessage = `The Tenant '${values.name}' has been ${
          'id' in values ? `updated` : `created`
        }.`;
        const toastStatus = 'success';
        addToast(toastMessage, toastStatus);
      }
    } catch (error) {
      console.error(error);
      const toastMessage =
        error instanceof Error ? error.message : lang.errors.default;
      const toastStatus = 'error';
      addToast(toastMessage, toastStatus);
    }
  };

  const onSwitchClick = async (
    id: string,
    enabled: boolean,
    tenantId: string,
    name: string
  ) => {
    await apiClient.featureFlagsApi.featureFlagControllerUpdate(
      { enabled },
      id
    );
    await fetchFeatureFlags(tenantId);
    addToast(
      `Successfully updated Feature Flag ${name} for Tenant ${
        tenant ? tenant.name : tenantId
      }`,
      'success'
    );
  };

  return (
    <>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        paddingLeft={5}
        paddingRight={5}
      >
        <Heading as="h1" fontSize={24} lineHeight={'32px'}>
          {lang.tenant.all.title}
        </Heading>
        <GenericButton
          type="button"
          text={lang.tenant.actions.add}
          onClick={() => handleEditTenantAction()}
        />
      </Stack>
      {isLoading ? (
        <Loading />
      ) : (
        <TenantsTable
          data={tenantsData}
          handleTenantAction={handleEditTenantAction}
          handleFeatureFlagAction={handleFeatureFlagAction}
        />
      )}
      <GenericButton
        type="button"
        text={lang.engagement.all.viewButton}
        onClick={() => navigate(ENGAGEMENTS_PAGE)}
      />

      <TenantModal
        tenant={tenant}
        isOpen={isEditOpen}
        onClose={onEditClose}
        onFormSubmit={
          onFormSubmit as (
            values: CreateTenantDto | UpdateTenantDto
          ) => Promise<void>
        }
      />

      <FeatureFlagModal
        featureFlags={featureFlags}
        isOpen={isFeatureFlagsOpen}
        onClose={onFeatureFlagsClose}
        onSwitchClick={onSwitchClick}
      />
    </>
  );
};

export default Tenants;
