import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
} from '@chakra-ui/react';

import KeyOpsLogo from '../../assets/images/KeyOpsLogo';
import PhysiciansImg from '../../assets/images/PhysiciansImg.png';
import GoogleButton from '../../components/GoogleButton';
import { ROOT } from '../../utils/internal-routes';
import lang from '../../utils/lang';

const Login: React.FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}>
        <GridItem h="100vh" px={16} py={8}>
          <Flex
            h={'100%'}
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={'center'}
          >
            <Box
              _hover={{ cursor: 'pointer' }}
              onClick={() => navigate(ROOT)}
              aria-label={'KeyOps Logo'}
            >
              <KeyOpsLogo />
            </Box>
            <Box
              as={Text}
              mt={4}
              pb={1}
              borderBottom={'1px'}
              fontSize={'xl'}
              fontFamily="Poppins"
            >
              {lang.login.title}
            </Box>
            <Box mt={6}>
              <GoogleButton />
            </Box>
          </Flex>
        </GridItem>
        <Center
          w="100%"
          h="100vh"
          bg="keyops.blue"
          display={{ base: 'none', md: 'flex' }}
        >
          <Box boxSize="md">
            <Image src={PhysiciansImg} alt="Physicians Img" />
          </Box>
        </Center>
      </Grid>
    </>
  );
};

export default Login;
