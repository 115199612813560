import React, { MutableRefObject, ReactNode, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react';

import GenericButton from './GenericButton';

export const CustomAlertDialog = ({
  action,
  messageElement,
  isOpen,
  onClose,
}: {
  action: string;
  messageElement: ReactNode;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const cancelRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        motionPreset="slideInBottom"
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="2xl" fontWeight="bold">
              {action}
            </AlertDialogHeader>
            <AlertDialogCloseButton />

            <AlertDialogBody fontSize="lg">{messageElement}</AlertDialogBody>

            <AlertDialogFooter>
              <GenericButton
                type="button"
                text="Cancel"
                background="gray.100"
                innerRef={cancelRef}
                onClick={onClose}
                margin={2}
              />

              <GenericButton
                type="button"
                text="Continue"
                background="keyops.blue"
                onClick={onClose}
                margin={2}
              />
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
