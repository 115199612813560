import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Container, FormLabel, Heading, Stack } from '@chakra-ui/react';
import {
  Analysis as AnalysisResponseDto,
  Question,
  QuestionAnalysis,
} from '@keyops-cep/api-client';
import { Field, FormikProps, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';

import { apiClient } from '../../api/swagger-codegen-api-config';
import { CustomBreadcrumb } from '../../components/CustomBreadcrumb';
import { CustomMarkdownInput } from '../../components/Form/CustomMarkdownInput';
import {
  ROOT,
  TENANTED_ENGAGEMENT_SINGLE_PAGE,
  TENANTED_ENGAGEMENTS_PAGE,
} from '../../utils/internal-routes';

interface QuestionAnalysisFormContent {
  content: string;
  surveySparrowQuestionId: string;
  questionId: string;
  questionLabel: string;
  questionAnalysisId?: string;
  questionPosition: number;
}
interface AnalysisFormContent {
  analysisId?: string;
  intro?: string;
  conclusion?: string;
  questionAnalyses: QuestionAnalysisFormContent[];
}

function getQuestionAnalysisFormContent(
  questions: Question[],
  questionAnalyses: QuestionAnalysis[]
): QuestionAnalysisFormContent[] {
  return questions.map((question) => {
    const questionAnalysis = questionAnalyses.find(
      (questionAnalysis) => questionAnalysis.questionId === question.id
    );
    return {
      questionLabel: question.label,
      content: questionAnalysis ? questionAnalysis.content : '',
      surveySparrowQuestionId: question.surveySparrowId,
      questionId: question.id,
      questionAnalysisId: questionAnalysis?.id,
      questionPosition: question.position,
    };
  });
}

function getFormValues(
  questions: Question[],
  questionAnalyses: QuestionAnalysis[],
  analysis?: AnalysisResponseDto
): AnalysisFormContent {
  return {
    analysisId: analysis?.id,
    intro: analysis?.intro || '',
    conclusion: analysis?.conclusion || '',
    questionAnalyses: getQuestionAnalysisFormContent(
      questions,
      questionAnalyses
    ),
  };
}

const Analysis: React.FunctionComponent = () => {
  // TODO add useEngagement hook, use useTenant hook
  const { engagementId, tenantId } = useParams();

  const [analysis, setAnalysis] = useState(
    undefined as AnalysisResponseDto | undefined
  );
  const [questionAnalyses, setQuestionAnalyses] = useState(
    [] as QuestionAnalysis[]
  );
  const [questions, setQuestions] = useState([] as Question[]);

  const fetchAnalysis = async (engagementId: string): Promise<void> => {
    try {
      const response =
        await apiClient.engagementsApi.engagementControllerGetAnalysis(
          engagementId
        );
      if (response) {
        setAnalysis(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchQuestions = async (engagementId: string): Promise<void> => {
    try {
      const response =
        await apiClient.engagementsApi.engagementControllerGetQuestions(
          engagementId
        );
      if (response) {
        setQuestions(response.data.sort((a, b) => a.position - b.position));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchQuestionAnalyses = async (analysisId: string): Promise<void> => {
    try {
      const response =
        await apiClient.analysesApi.analysisControllerGetQuestionAnalyses(
          analysisId
        );
      if (response) {
        setQuestionAnalyses(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!engagementId) throw new Error('No engagementId');
    fetchAnalysis(engagementId);
    fetchQuestions(engagementId);
  }, [engagementId]);

  useEffect(() => {
    if (!analysis) return;
    fetchQuestionAnalyses(analysis.id);
  }, [analysis]);

  const validationSchema = yup.object({
    intro: yup.string(),
    conclusion: yup.string(),
    questionAnalyses: yup.array().of(
      yup.object().shape({
        content: yup.string(),
      })
    ),
  });

  async function submitAnalysis(values: AnalysisFormContent): Promise<string> {
    if (values.analysisId) {
      await apiClient.analysesApi.analysisControllerUpdate(
        {
          intro: values.intro ?? '',
          conclusion: values.conclusion ?? '',
        },
        values.analysisId
      );
      return values.analysisId;
    }
    if (!engagementId || !tenantId)
      throw new Error(`Missing Engagement or Tenant ID`);
    const analysis = await apiClient.analysesApi.analysisControllerCreate({
      intro: values.intro ?? '',
      conclusion: values.conclusion ?? '',
      engagementId: engagementId,
      tenantId: tenantId,
    });
    if (!analysis)
      throw new Error(
        `Missing the Analysis we just created - something went wrong...`
      );
    return analysis.data.id;
  }

  const onSubmit = async (values: AnalysisFormContent) => {
    const analysisId = await submitAnalysis(values);

    await Promise.all(
      values.questionAnalyses.map(async (questionAnalysis) => {
        if (questionAnalysis.questionAnalysisId) {
          if (questionAnalysis.content) {
            apiClient.questionAnalysesApi.questionAnalysisControllerUpdate(
              {
                content: questionAnalysis.content,
              },
              questionAnalysis.questionAnalysisId
            );
            return;
          }
          apiClient.questionAnalysesApi.questionAnalysisControllerRemove(
            questionAnalysis.questionAnalysisId
          );
          return;
        }

        if (questionAnalysis.content) {
          if (!tenantId) throw new Error(`Missing Tenant ID`);
          apiClient.questionAnalysesApi.questionAnalysisControllerCreate({
            analysisId,
            content: questionAnalysis.content,
            surveySparrowQuestionId: questionAnalysis.surveySparrowQuestionId,
            tenantId,
          });
        }
      })
    );
    if (engagementId) {
      fetchAnalysis(engagementId);
    }
    fetchQuestionAnalyses(analysisId);
  };

  const formik: FormikProps<AnalysisFormContent> =
    useFormik<AnalysisFormContent>({
      initialValues: getFormValues(questions, questionAnalyses, analysis),
      validationSchema,
      onSubmit,
    });

  useEffect(() => {
    formik.setValues(getFormValues(questions, questionAnalyses, analysis));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analysis, questionAnalyses, questions]);

  if (!tenantId || !engagementId) return <>Loading...</>;

  return (
    <FormikProvider value={formik}>
      <Container maxW="4xl">
        {/* TODO figure out a good way to get the Tenant and Engagement names here, 
         we don't really want to have to make another 2 API calls just for this */}
        <CustomBreadcrumb
          list={[
            { text: 'Home', link: ROOT },
            {
              text: 'Engagements',
              link: TENANTED_ENGAGEMENTS_PAGE.replace(':tenantId', tenantId),
            },
            {
              text: 'Engagement',
              link: TENANTED_ENGAGEMENT_SINGLE_PAGE.replace(
                ':tenantId',
                tenantId
              ).replace(':engagementId', engagementId),
            },
            {
              text: 'Analysis',
              link: '',
            },
          ]}
        />
        <Heading
          as="h1"
          fontSize={24}
          lineHeight={'32px'}
          textAlign="center"
          margin={5}
        >
          Analysis Form
        </Heading>

        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={3}>
            <FormLabel htmlFor="intro">Intro</FormLabel>
            <Field
              as={CustomMarkdownInput}
              id="intro"
              {...formik.getFieldProps('intro')}
            />

            {formik.values.questionAnalyses.map((questionAnalysis, index) => (
              <div key={index}>
                <FormLabel htmlFor={`questionAnalyses[${index}].content`}>
                  {questionAnalysis.questionPosition + 1}.{' '}
                  {questionAnalysis.questionLabel}
                </FormLabel>
                <Field
                  as={CustomMarkdownInput}
                  id={`questionAnalyses[${index}].content`}
                  {...formik.getFieldProps(
                    `questionAnalyses[${index}].content`
                  )}
                />
              </div>
            ))}

            <FormLabel htmlFor="conclusion">Conclusion</FormLabel>
            <Field
              as={CustomMarkdownInput}
              id="conclusion"
              {...formik.getFieldProps('conclusion')}
            />

            <Button type="submit">Submit</Button>
          </Stack>
        </form>
      </Container>
    </FormikProvider>
  );
};

export default Analysis;
