import React, { Fragment } from 'react';
import { NavigateFunction } from 'react-router-dom';
import {
  Card,
  FormControl,
  Heading,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';

import {
  EngagementDto,
  UpdateEngagementDto,
} from '../../../api/dto/engagement.dto';
import { updateEngagementCall } from '../../../api/functions/engagements.functions';
import { CustomAlertDialog } from '../../../components/CustomAlertDialog';
import { CustomToast } from '../../../components/CustomToast';
import { CustomDateInput } from '../../../components/Form/CustomDateInput';
import { CustomNumberInput } from '../../../components/Form/CustomNumberInput';
import { CustomTextInput } from '../../../components/Form/CustomTextInput';
import GenericButton from '../../../components/GenericButton';
import { TENANTED_ENGAGEMENTS_PAGE } from '../../../utils/internal-routes';
import lang from '../../../utils/lang';

import 'react-datepicker/dist/react-datepicker.css';

// Define the form validation schema using Yup
const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  displayTitle: yup.string(),
  surveySparrowId: yup.string(),
  startDate: yup.date(),
  endDate: yup
    .date()
    .min(
      yup.ref('startDate'),
      'End date must be greater than or equal to the start date'
    ),
  responseCountTarget: yup.number(),
});

export function UpdateEngagementForm({
  engagement,
  isDisabled = false,
  navigate,
}: {
  engagement: EngagementDto;
  isDisabled?: boolean;
  navigate: NavigateFunction;
}) {
  const { addToast } = CustomToast();

  const onSubmit = async (values: UpdateEngagementDto) => {
    try {
      const response = await updateEngagementCall(engagement.id, values);
      if (response?.status === 201) {
        const toastMessage = `The engagement '${values.name}' has been updated!`;
        const toastStatus = 'success';
        addToast(toastMessage, toastStatus);
        navigate(
          TENANTED_ENGAGEMENTS_PAGE.replace(':tenantId', response.data.tenantId)
        );
      }
    } catch (error) {
      console.error(error);
      const toastMessage =
        error instanceof Error ? error.message : lang.errors.default;
      const toastStatus = 'error';
      addToast(toastMessage, toastStatus);
    }
  };

  const initialValues: UpdateEngagementDto = {
    name: engagement.name,
    displayTitle: engagement.displayTitle,
    startDate: engagement.startDate
      ? new Date(engagement.startDate)
      : undefined,
    endDate: engagement.endDate ? new Date(engagement.endDate) : undefined,
    completedDate: engagement.completedDate
      ? new Date(engagement.completedDate)
      : undefined,
    responseCountTarget: engagement.responseCountTarget,
    responseCount: engagement.responseCount,
    surveySparrowId: engagement.surveySparrowId,
  };

  const formik: FormikProps<UpdateEngagementDto> =
    useFormik<UpdateEngagementDto>({
      initialValues,
      validationSchema,
      onSubmit,
      enableReinitialize: true,
    });

  // Handle alert dialog for updating surveySparrowId
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleSurveySparrowIdChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    formik.handleChange(e);
    if (formik.initialValues.surveySparrowId !== e.target.value) {
      onOpen();
    }
  };

  return (
    <Card align="left" variant="elevated" width="xl" padding={3}>
      <Heading
        as="h2"
        fontSize={20}
        lineHeight={'20px'}
        textAlign="left"
        margin={3}
      >
        {lang.engagement.single.details}
      </Heading>
      <form onSubmit={formik.handleSubmit} style={{ width: '50%' }}>
        {/* Engagement Name */}
        <CustomTextInput
          name="name"
          label="Internal Name"
          placeholder=""
          helperMessage=""
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isMandatory={true}
        />
        {formik.touched.name && formik.errors.name && (
          <Text as="sub" color="red">
            {formik.errors.name}
          </Text>
        )}
        {/* Engagement displayTitle */}
        <CustomTextInput
          name="displayTitle"
          label="Engagement Display Title"
          placeholder=""
          helperMessage=""
          value={formik.values.displayTitle}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isMandatory={false}
        />
        {formik.touched.displayTitle && formik.errors.displayTitle && (
          <Text as="sub" color="red">
            {formik.errors.displayTitle}
          </Text>
        )}

        {/* Engagement surveySparrowId */}
        <CustomTextInput
          name="surveySparrowId"
          label="Survey Sparrow ID"
          placeholder=""
          helperMessage=""
          value={formik.values.surveySparrowId}
          onChange={(e) => handleSurveySparrowIdChange(e)}
          onBlur={formik.handleBlur}
          isMandatory={false}
        />
        {formik.touched.surveySparrowId && formik.errors.surveySparrowId && (
          <Text as="sub" color="red">
            {formik.errors.surveySparrowId}
          </Text>
        )}

        <FormControl padding={3}>
          {/* Engagement startDate */}
          <CustomDateInput
            name="startDate"
            label="Start Date"
            placeholder="Pick a date or leave it blank"
            value={formik.values.startDate}
            minDate={undefined}
            isMandatory={true}
            onChange={(date) => formik.setFieldValue('startDate', date)}
            onBlur={formik.handleBlur}
          />

          {formik.touched.startDate && formik.errors.startDate && (
            <Text as="sub" color="red">
              {formik.errors.startDate}
            </Text>
          )}
          {/* Engagement endDate */}
          <CustomDateInput
            name="endDate"
            label="End Date"
            placeholder="Pick a date or leave it blank"
            minDate={formik.values.startDate}
            value={formik.values.endDate}
            isMandatory={true}
            onChange={(date) => formik.setFieldValue('endDate', date)}
            onBlur={formik.handleBlur}
          />

          {formik.touched.endDate && formik.errors.endDate && (
            <Text as="sub" color="red">
              {formik.errors.endDate}
            </Text>
          )}
        </FormControl>

        {/* Engagement responseCountTarget */}
        <CustomNumberInput
          name="responseCountTarget"
          label="Targeted Respondents"
          value={formik.values.responseCountTarget}
          isMandatory={false}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        {formik.touched.responseCountTarget &&
          formik.errors.responseCountTarget && (
            <Text as="sub" color="red">
              {formik.errors.responseCountTarget}
            </Text>
          )}
        <GenericButton
          type="submit"
          text="Save"
          background="keyops.blue"
          onClick={formik.handleSubmit}
          isDisabled={isDisabled}
        />
      </form>

      <CustomAlertDialog
        action={`Survey Sparrow ID Change`}
        messageElement={
          <Fragment>
            You are changing the Survey Sparrow ID from{' '}
            <strong>{initialValues.surveySparrowId}</strong> to{' '}
            <strong>{formik.values.surveySparrowId}</strong>.<br />
            <br />
            Please note that submitting this change will result in the loss of
            your commentary for each question. The introduction and summary will
            remain.
          </Fragment>
        }
        isOpen={isOpen}
        onClose={onClose}
      />
    </Card>
  );
}
