import { AxiosResponse } from 'axios';

import { METHOD_TYPES } from '../api-constants';
import { performApiRequest } from '../api-request-builder';
import {
  CreateTenantDto,
  TenantResponseDto,
  UpdateTenantDto,
} from '../dto/tenant.dto';
import {
  GET_TENANT_ENDPOINT,
  GET_TENANTS_BATCH_ENDPOINT,
  GET_TENANTS_ENDPOINT,
  POST_TENANT_ENDPOINT,
  UPDATE_TENANT_IS_ACTIVE_ENDPOINT,
} from '../routes/tenant.routes';

export const getTenantsCall = async () => {
  let response;
  try {
    response = await performApiRequest({
      method: METHOD_TYPES.GET,
      endpoint: GET_TENANTS_ENDPOINT,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }

  return response;
};

export const getTenantCall = async (
  id: string
): Promise<AxiosResponse<TenantResponseDto, undefined> | undefined> => {
  try {
    if (!id) {
      throw new Error(`No tenant Id`);
    }
    return await performApiRequest({
      method: METHOD_TYPES.GET,
      endpoint: GET_TENANT_ENDPOINT.replace(':tenantId', id),
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getTenantsByIds = async (
  ids: string[]
): Promise<AxiosResponse<TenantResponseDto[] | undefined> | undefined> => {
  try {
    if (ids.length < 1) {
      throw new Error(`No tenant Id`);
    }
    return await performApiRequest({
      method: METHOD_TYPES.POST,
      endpoint: GET_TENANTS_BATCH_ENDPOINT,
      data: { ids },
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createTenantCall = async (tenant: CreateTenantDto) => {
  try {
    return await performApiRequest({
      method: METHOD_TYPES.POST,
      endpoint: POST_TENANT_ENDPOINT,
      params: {},
      data: { ...tenant },
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateTenantCall = async (
  tenantId: string,
  tenant: UpdateTenantDto
): Promise<AxiosResponse<TenantResponseDto, undefined> | undefined> => {
  try {
    return await performApiRequest({
      method: METHOD_TYPES.POST,
      endpoint: GET_TENANT_ENDPOINT.replace(':tenantId', tenantId),
      params: {},
      data: { ...tenant },
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateTenantIsActiveCall = async (
  tenantId: string,
  isActive: boolean
): Promise<AxiosResponse<TenantResponseDto, undefined> | undefined> => {
  try {
    return await performApiRequest({
      method: METHOD_TYPES.POST,
      endpoint: UPDATE_TENANT_IS_ACTIVE_ENDPOINT.replace(':tenantId', tenantId),
      params: {},
      data: { isActive },
    });
  } catch (error) {
    console.error(error);
    throw error;
  }
};
