import React, { Fragment } from 'react';
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import { ComparableEngagements, Engagement } from '@keyops-cep/api-client';

import { EngagementDto } from '../../../api/dto/engagement.dto';
import { GenericLink } from '../../../components/GenericLink';
import { CUSTOMER_UI_ENGAGEMENT_DETAILS } from '../../../utils/external-routes';
import { navigateToCustomerUi } from '../../../utils/functions/navigate-to-customer-ui';
import {
  ENGAGEMENTS_COMPARISON_PAGE,
  TENANTED_ENGAGEMENT_SINGLE_PAGE,
} from '../../../utils/internal-routes';
import lang from '../../../utils/lang';

type EngagementSettingsCardProps = {
  comparableEngagements: ComparableEngagements[];
  currentEngagement: EngagementDto;
};

export const ComparableEngagementsCard = ({
  comparableEngagements,
  currentEngagement,
}: EngagementSettingsCardProps) => {
  return (
    <Card variant="elevated" padding={3}>
      <CardHeader>
        <Heading as="h2" fontSize={20} lineHeight={'20px'} textAlign="left">
          {lang.engagement.single.comparableEngagement.title}
        </Heading>
        <Box pt={3}>
          <Text>
            {lang.engagement.single.comparableEngagement.current.themes}
          </Text>
          <Text pt={1}>{currentEngagement?.themes}</Text>
        </Box>
      </CardHeader>

      <CardBody justifyContent="start" padding={3} pt={2} pl={5}>
        <Table variant="unstyled">
          <Tbody>
            {comparableEngagements.map(
              (comparableEngagement: ComparableEngagements) => {
                // NB: disable the link to Comparison page when no comparable questions and no global report on ComparableEngagements
                const hasNoComparableQuestions =
                  comparableEngagement?.comparableQuestions === undefined ||
                  comparableEngagement?.comparableQuestions?.length === 0;
                const hasNoComparisonReport = !comparableEngagement?.report;
                return comparableEngagement.engagements.map(
                  (engagement: Engagement, key: number) => {
                    if (engagement.id !== currentEngagement.id) {
                      return (
                        <Tr key={key}>
                          <Td>
                            <GenericLink
                              text={engagement.name}
                              href={TENANTED_ENGAGEMENT_SINGLE_PAGE.replace(
                                ':tenantId',
                                engagement.tenantId
                              ).replace(':engagementId', engagement.id)}
                            />
                          </Td>
                          <Td maxW={'150px'}>{engagement?.displayTitle}</Td>
                          <Td maxW={'150px'}>
                            {lang.engagement.single.comparableEngagement.themes}
                            : {engagement?.themes}
                          </Td>
                          <Td fontSize={'12px'}>
                            <Box display={'block'}>
                              <GenericLink
                                text={lang.engagement.single.preview}
                                onClick={() => {
                                  navigateToCustomerUi(
                                    CUSTOMER_UI_ENGAGEMENT_DETAILS.replace(
                                      ':engagementId',
                                      engagement.id
                                    )
                                  );
                                }}
                              />
                            </Box>
                            <Box display={'block'}>
                              <GenericLink
                                disable={
                                  hasNoComparableQuestions &&
                                  hasNoComparisonReport
                                }
                                text={
                                  lang.engagement.single.comparisonAnalysis
                                    .linkTitle
                                }
                                href={ENGAGEMENTS_COMPARISON_PAGE.replace(
                                  ':tenantId',
                                  currentEngagement.tenantId
                                )
                                  .replace(
                                    ':engagementId',
                                    currentEngagement.id
                                  )
                                  .replace(
                                    ':comparableEngagementId',
                                    engagement.id
                                  )}
                              />
                            </Box>
                          </Td>
                        </Tr>
                      );
                    }
                    return <Fragment key={key}></Fragment>;
                  }
                );
              }
            )}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};
