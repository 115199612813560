import { CepApiClient, getAxiosInterceptor } from '@keyops-cep/api-client';
import axios from 'axios';

import { getEnvironmentVariables } from '../utils/functions/env-utils';
import { getAccessToken } from '../utils/get-tokens';
import { LOGIN } from '../utils/internal-routes';

import { removeTokens, setNewTokens } from './functions/auth.functions';

const { REACT_APP_API_URL_SWAGGER } = getEnvironmentVariables();
const accessToken = getAccessToken();

// Response Interceptor to globally handle errors
const axiosInterceptor = getAxiosInterceptor(
  setNewTokens,
  removeTokens,
  LOGIN,
  axios
);
axios.interceptors.response.use(
  axiosInterceptor.responseHandler,
  axiosInterceptor.errorHandler
);

export const apiClient = new CepApiClient(
  REACT_APP_API_URL_SWAGGER,
  accessToken ?? '',
  axios
);
