import React from 'react';
import { Checkbox, FormControl, FormLabel } from '@chakra-ui/react';

export const CustomCheckBoxInput = ({
  name,
  label,
  value,
  isMandatory = false,
  isDisabled = false,
  onChange,
  onBlur,
}: {
  name: string;
  label: string;
  value: boolean;
  onChange: (e: React.ChangeEvent<unknown>) => void;
  onBlur?: (e: React.FocusEvent<unknown, Element>) => void;
  isMandatory?: boolean;
  isDisabled?: boolean;
}) => {
  return (
    <FormControl padding={3}>
      <Checkbox
        id={name}
        name={name}
        isChecked={value}
        onChange={onChange}
        onBlur={onBlur}
        alignItems="baseline"
        isDisabled={isDisabled}
      >
        <FormLabel htmlFor={name}>
          {isMandatory
            ? label.charAt(0).toUpperCase() + label.slice(1) + '*'
            : label.charAt(0).toUpperCase() + label.slice(1)}
        </FormLabel>
      </Checkbox>
    </FormControl>
  );
};
