import React, { Dispatch, SetStateAction } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UseModalProps,
} from '@chakra-ui/react';

import { AdminDto } from '../../../api/dto/admin.dto';
import { TenantResponseDto } from '../../../api/dto/tenant.dto';
import { CreateUserDto, UpdateUserDto } from '../../../api/dto/user.dto';
import lang from '../../../utils/lang';

import { UserForm } from './userForm';

export function UserModal({
  isAdmin,
  tenant,
  user,
  isOpen,
  onClose,
  onFormSubmit,
  setUser,
}: {
  isAdmin: boolean;
  tenant: TenantResponseDto | undefined;
  user?: CreateUserDto | UpdateUserDto;
  isOpen: boolean;
  onClose: UseModalProps['onClose'];
  onFormSubmit: (
    values: CreateUserDto | UpdateUserDto | AdminDto
  ) => Promise<void>;
  setUser?: Dispatch<SetStateAction<UpdateUserDto>>;
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isAdmin
            ? lang.admin.add.action
            : user && 'id' in user
            ? lang.user.single.update.action
            : lang.user.single.add.action}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <UserForm
            user={user}
            isAdmin={isAdmin}
            tenantId={tenant?.id}
            onSubmit={onFormSubmit}
            setUser={setUser}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
