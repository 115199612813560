import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { ComparableQuestions as ComparableQuestionsEntity } from '@keyops-cep/api-client';

import lang from '../../../utils/lang';

import { QuestionsComparisonCard } from './questionsComparisonCard';

type ComparableQuestionsProps = {
  comparableQuestions: ComparableQuestionsEntity[];
  currentEngagementName: string;
  comparableEngagementName: string;
};
export const ComparableQuestions = ({
  comparableQuestions,
  currentEngagementName,
  comparableEngagementName,
}: ComparableQuestionsProps) => {
  console.log(`comparableQuestions`, comparableQuestions);
  return (
    <Box m={0} p={0}>
      <Text fontSize={16} textAlign="left" pt={5} pb={5}>
        {comparableQuestions?.length > 0
          ? lang.engagement.single.comparisonAnalysis.questions.description
          : lang.engagement.single.comparisonAnalysis.questions.notFound}
      </Text>
      {comparableQuestions?.length > 0 &&
        comparableQuestions.map((comparableQuestion, key) => (
          <QuestionsComparisonCard
            comparableQuestions={comparableQuestion}
            currentEngagementName={currentEngagementName}
            comparableEngagementName={comparableEngagementName}
            prevKey={key}
            key={key}
          />
        ))}
    </Box>
  );
};
