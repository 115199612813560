/* tslint:disable */
/* eslint-disable */
/**
 * Keyops CEP
 * Customer Engagement Platform API documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface Tenant
 */
export interface Tenant {
    /**
     * The ID of the database object
     * @type {string}
     * @memberof Tenant
     */
    id: string;
    /**
     * The date the object was created
     * @type {string}
     * @memberof Tenant
     */
    createdAt: string;
    /**
     * The date the object was last updated
     * @type {string}
     * @memberof Tenant
     */
    updatedAt: string;
    /**
     * The name of the tenant
     * @type {string}
     * @memberof Tenant
     */
    name: string;
    /**
     * The country the tenant is in
     * @type {string}
     * @memberof Tenant
     */
    country: TenantCountryEnum;
    /**
     * Whether the tenant is active or not
     * @type {boolean}
     * @memberof Tenant
     */
    isActive: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum TenantCountryEnum {
    US = 'US',
    CAN = 'CAN'
}

