import React from 'react';
import {
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';

export const CustomNumberInput = ({
  name,
  label,
  value,
  isMandatory,
  onChange,
  onBlur,
}: {
  name: string;
  label: string;
  value: number | undefined;
  onChange: (e: React.ChangeEvent<unknown>) => void;
  onBlur: (e: React.FocusEvent<unknown, Element>) => void;
  isMandatory: boolean;
}) => {
  return (
    <FormControl padding={3}>
      <FormLabel htmlFor={name}>
        {isMandatory
          ? label.charAt(0).toUpperCase() + label.slice(1) + '*'
          : label.charAt(0).toUpperCase() + label.slice(1)}
      </FormLabel>
      <NumberInput size="sm" maxW={20} id={name} name={name} value={value}>
        <NumberInputField
          type="number"
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        />
      </NumberInput>
    </FormControl>
  );
};
