import React from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseModalProps,
} from '@chakra-ui/react';

import { CreateEngagementDto } from '../../../api/dto/engagement.dto';
import lang from '../../../utils/lang';

import { CreateEngagementForm } from './createEngagementForm';

export function CreateEngagementModal({
  fetchEngagements,
  isOpen,
  onClose,
  onFormSubmit,
}: {
  fetchEngagements: () => Promise<void>;
  isOpen: boolean;
  onClose: UseModalProps['onClose'];
  onFormSubmit: (values: CreateEngagementDto) => Promise<void>;
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{lang.engagement.single.add.action}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <CreateEngagementForm
            fetchEngagements={fetchEngagements}
            onClose={onClose}
            onSubmit={onFormSubmit}
          />
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
