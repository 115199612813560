import React from 'react';
import { FiLogOut, FiUser } from 'react-icons/fi';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Stack, useColorModeValue } from '@chakra-ui/react';

import KeyOpsLogo from '../../assets/images/KeyOpsLogo';
import { ADMINS_PAGE, LOGOUT, TENANTS_PAGE } from '../../utils/internal-routes';
import lang from '../../utils/lang';

const NavBar: React.FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box sx={{ pb: 5 }}>
        <Flex
          bg={'#EBF8FF'}
          minH={'60px'}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.900')}
          align={'center'}
        >
          <Box
            _hover={{ cursor: 'pointer' }}
            onClick={() => navigate(TENANTS_PAGE)}
            alignSelf={{ base: 'center', md: 'flex-start' }}
            aria-label={'KeyOps Logo'}
          >
            <KeyOpsLogo />
          </Box>

          <Stack
            flex={{ base: 1, md: 'end' }}
            justify={'flex-end'}
            direction={'row'}
            spacing={3}
          >
            <Button
              leftIcon={<FiUser />}
              color="keyops.blue"
              variant="ghost"
              onClick={() => navigate(ADMINS_PAGE)}
            >
              {lang.admin.all.viewButton}
            </Button>
            <Button
              leftIcon={<FiLogOut />}
              color="keyops.blue"
              variant="ghost"
              onClick={() => navigate(LOGOUT)}
            >
              {lang.logout.action}
            </Button>
          </Stack>
        </Flex>
      </Box>
      <Outlet />
    </>
  );
};

export default NavBar;
