import { getAxiosInterceptor } from '@keyops-cep/api-client';
import axios from 'axios';

import { getEnvironmentVariables } from '../utils/functions/env-utils';
import { LOGIN, LOGOUT } from '../utils/internal-routes';

import { removeTokens, setNewTokens } from './functions/auth.functions';
import {
  GOOGLE_LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  REFRESH_TOKEN_ENDPOINT,
  UNCOMMON_ENDPOINTS,
} from './routes/auth.routes';
import { HEADERS } from './api-constants';
const { REACT_APP_API_URL } = getEnvironmentVariables();

interface AxiosApiCallPropTypes<T = Record<string, unknown>> {
  method: string;
  endpoint: string;
  params?: Record<string, string>;
  data?: T | string;
  extraData?: Record<string, string | undefined>;
}

export async function performApiRequest<T>({
  method,
  endpoint,
  params,
  data,
  extraData,
}: AxiosApiCallPropTypes<T>) {
  if (UNCOMMON_ENDPOINTS.includes(endpoint)) {
    switch (endpoint) {
      case GOOGLE_LOGIN_ENDPOINT:
        HEADERS.Authorization = `Bearer ${extraData?.idToken}`;
        break;
      case REFRESH_TOKEN_ENDPOINT:
        HEADERS['x-refresh-token'] = localStorage.getItem(
          'KeyOps_RefreshToken'
        );
        break;
      case LOGOUT_ENDPOINT:
        HEADERS.Authorization = `Bearer ${localStorage.getItem(
          'KeyOps_AccessToken'
        )}`;
        HEADERS['x-refresh-token'] = localStorage.getItem(
          'KeyOps_RefreshToken'
        );
        break;
      default:
        break;
    }
  } else {
    const accessToken = localStorage.getItem('KeyOps_AccessToken');
    // if there is no access token in local storage, log user out
    if (!accessToken) {
      try {
        await setNewTokens();
      } catch (error) {
        window.location.href = LOGOUT;
        return;
      }
    } else {
      HEADERS.Authorization = `Bearer ${localStorage.getItem(
        'KeyOps_AccessToken'
      )}`;
    }
  }

  const request = {
    headers: HEADERS,
    baseURL: REACT_APP_API_URL,
    method,
    withCredentials: true,
    url: endpoint,
    params,
    data,
  };

  return await axios(request);
}

// Response Interceptor to globally handle errors
const axiosInterceptor = getAxiosInterceptor(
  setNewTokens,
  removeTokens,
  LOGIN,
  axios
);
axios.interceptors.response.use(
  axiosInterceptor.responseHandler,
  axiosInterceptor.errorHandler
);
