export const GET_ENGAGEMENTS_ENDPOINT = '/engagements';
export const GET_ENGAGEMENT_ENDPOINT = '/engagements/:engagementId';

export const POST_ENGAGEMENT_ENDPOINT = '/engagements';
export const UPDATE_ENGAGEMENT_ENDPOINT = '/engagements/:engagementId';
export const UPDATE_ENGAGEMENT_STATUS_ENDPOINT =
  '/engagements/:engagementId/update-status';
export const UPDATE_ENGAGEMENT_HIDE_DEMOGRAPHICS_ENDPOINT =
  '/engagements/:engagementId/hide-demographics';
export const GET_ENGAGEMENT_ANALYSIS = '/engagements/:engagementId/analysis';
export const GET_ENGAGEMENT_QUESTIONS = '/engagements/:engagementId/questions';
export const GET_ENGAGEMENT_RESPONSES = '/engagements/:engagementId/responses';

export const REFRESH_SURVEY_ENDPOINT =
  '/engagements/:engagementId/refresh-survey';
export const BULK_UPDATE_DEMOGRAPHICS_ENDPOINT =
  '/engagements/:engagementId/respondent-demographics';
