import React from 'react';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from '@chakra-ui/react';

export const CustomEmailInput = ({
  name,
  label,
  placeholder,
  helperMessage,
  value,
  isMandatory,
  onChange,
  onBlur,
}: {
  name: string;
  label: string;
  helperMessage: string;
  value: string | undefined;
  onChange: (e: React.ChangeEvent<unknown>) => void;
  onBlur: (e: React.FocusEvent<unknown, Element>) => void;
  isMandatory: boolean;
  placeholder?: string;
}) => {
  return (
    <FormControl padding={3} paddingBottom={0}>
      <FormLabel htmlFor={name}>
        {isMandatory
          ? label.charAt(0).toUpperCase() + label.slice(1) + '*'
          : label.charAt(0).toUpperCase() + label.slice(1)}
      </FormLabel>
      <Input
        type="email"
        id={name}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <FormHelperText>{helperMessage}</FormHelperText>
    </FormControl>
  );
};
