/* tslint:disable */
/* eslint-disable */
/**
 * Keyops CEP
 * Customer Engagement Platform API documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Analysis } from '../models';
import { CreateAnalysisDto } from '../models';
import { QuestionAnalysis } from '../models';
import { UpdateAnalysisDto } from '../models';
/**
 * AnalysesApi - axios parameter creator
 * @export
 */
export const AnalysesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates an Analysis
         * @param {CreateAnalysisDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisControllerCreate: async (body: CreateAnalysisDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling analysisControllerCreate.');
            }
            const localVarPath = `/api/analyses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all Analyses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analyses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an Analysis by it's ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling analysisControllerFindOne.');
            }
            const localVarPath = `/api/analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the QuestionAnalyses for an Analysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisControllerGetQuestionAnalyses: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling analysisControllerGetQuestionAnalyses.');
            }
            const localVarPath = `/api/analyses/{id}/question-analyses`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an Analysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling analysisControllerRemove.');
            }
            const localVarPath = `/api/analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an Analysis
         * @param {UpdateAnalysisDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        analysisControllerUpdate: async (body: UpdateAnalysisDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling analysisControllerUpdate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling analysisControllerUpdate.');
            }
            const localVarPath = `/api/analyses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalysesApi - functional programming interface
 * @export
 */
export const AnalysesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates an Analysis
         * @param {CreateAnalysisDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisControllerCreate(body: CreateAnalysisDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Analysis>>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).analysisControllerCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets all Analyses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<Analysis>>>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).analysisControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets an Analysis by it's ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Analysis>>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).analysisControllerFindOne(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets the QuestionAnalyses for an Analysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisControllerGetQuestionAnalyses(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<QuestionAnalysis>>>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).analysisControllerGetQuestionAnalyses(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deletes an Analysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).analysisControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates an Analysis
         * @param {UpdateAnalysisDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisControllerUpdate(body: UpdateAnalysisDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Analysis>>> {
            const localVarAxiosArgs = await AnalysesApiAxiosParamCreator(configuration).analysisControllerUpdate(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AnalysesApi - factory interface
 * @export
 */
export const AnalysesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Creates an Analysis
         * @param {CreateAnalysisDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisControllerCreate(body: CreateAnalysisDto, options?: AxiosRequestConfig): Promise<AxiosResponse<Analysis>> {
            return AnalysesApiFp(configuration).analysisControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all Analyses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisControllerFindAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Analysis>>> {
            return AnalysesApiFp(configuration).analysisControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an Analysis by it's ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Analysis>> {
            return AnalysesApiFp(configuration).analysisControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the QuestionAnalyses for an Analysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisControllerGetQuestionAnalyses(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<QuestionAnalysis>>> {
            return AnalysesApiFp(configuration).analysisControllerGetQuestionAnalyses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an Analysis
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AnalysesApiFp(configuration).analysisControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an Analysis
         * @param {UpdateAnalysisDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async analysisControllerUpdate(body: UpdateAnalysisDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Analysis>> {
            return AnalysesApiFp(configuration).analysisControllerUpdate(body, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalysesApi - object-oriented interface
 * @export
 * @class AnalysesApi
 * @extends {BaseAPI}
 */
export class AnalysesApi extends BaseAPI {
    /**
     * 
     * @summary Creates an Analysis
     * @param {CreateAnalysisDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public async analysisControllerCreate(body: CreateAnalysisDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<Analysis>> {
        return AnalysesApiFp(this.configuration).analysisControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets all Analyses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public async analysisControllerFindAll(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<Analysis>>> {
        return AnalysesApiFp(this.configuration).analysisControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets an Analysis by it's ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public async analysisControllerFindOne(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Analysis>> {
        return AnalysesApiFp(this.configuration).analysisControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets the QuestionAnalyses for an Analysis
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public async analysisControllerGetQuestionAnalyses(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<QuestionAnalysis>>> {
        return AnalysesApiFp(this.configuration).analysisControllerGetQuestionAnalyses(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Deletes an Analysis
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public async analysisControllerRemove(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AnalysesApiFp(this.configuration).analysisControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Updates an Analysis
     * @param {UpdateAnalysisDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalysesApi
     */
    public async analysisControllerUpdate(body: UpdateAnalysisDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Analysis>> {
        return AnalysesApiFp(this.configuration).analysisControllerUpdate(body, id, options).then((request) => request(this.axios, this.basePath));
    }
}
