const ACCESS_TOKEN_KEY = 'KeyOps_AccessToken';
const REFRESH_TOKEN_KEY = 'KeyOps_RefreshToken';

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}
export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export function setAccessToken(accessToken: string) {
  return localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
}
export function setRefreshToken(refreshToken: string) {
  return localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
}

export function removeAccessToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
}
export function removeRefreshToken() {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
}
