import {
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
} from '../../utils/get-tokens';
import { METHOD_TYPES } from '../api-constants';
import { performApiRequest } from '../api-request-builder';
import {
  GOOGLE_LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  REFRESH_TOKEN_ENDPOINT,
} from '../routes/auth.routes';

export const loginApiCall = async (idToken: string | undefined) => {
  const response = await performApiRequest({
    method: METHOD_TYPES.GET,
    endpoint: GOOGLE_LOGIN_ENDPOINT,
    extraData: {
      idToken: idToken,
    },
  });
  if (response?.status === 200) {
    localStorage.setItem('KeyOps_AccessToken', response.data.accessToken);
    localStorage.setItem('KeyOps_RefreshToken', response.data.refreshToken);
  }
  return response;
};

export const logoutApiCall = async () => {
  return await performApiRequest({
    method: METHOD_TYPES.POST,
    endpoint: LOGOUT_ENDPOINT,
  });
};

export async function setNewTokens() {
  const response = await getNewTokens();
  if (response?.status === 201) {
    setAccessToken(response.data.accessToken);
    setRefreshToken(response.data.refreshToken);
  }
  return response;
}

export async function getNewTokens() {
  return await performApiRequest({
    method: METHOD_TYPES.POST,
    endpoint: REFRESH_TOKEN_ENDPOINT,
  });
}

export async function removeTokens() {
  removeAccessToken();
  removeRefreshToken();
}
