/* tslint:disable */
/* eslint-disable */
/**
 * Keyops CEP
 * Customer Engagement Platform API documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { QuestionAnnotation } from './question-annotation';
import { QuestionChoice } from './question-choice';
import { QuestionComponent } from './question-component';
/**
 * 
 * @export
 * @interface AggregateQuestion
 */
export interface AggregateQuestion {
    /**
     * The ID of the database object
     * @type {string}
     * @memberof AggregateQuestion
     */
    id: string;
    /**
     * The date the object was created
     * @type {string}
     * @memberof AggregateQuestion
     */
    createdAt: string;
    /**
     * The date the object was last updated
     * @type {string}
     * @memberof AggregateQuestion
     */
    updatedAt: string;
    /**
     * The label of the question
     * @type {string}
     * @memberof AggregateQuestion
     */
    label: string;
    /**
     * The type of question
     * @type {string}
     * @memberof AggregateQuestion
     */
    type: AggregateQuestionTypeEnum;
    /**
     * The subtype of the question
     * @type {string}
     * @memberof AggregateQuestion
     */
    subType?: AggregateQuestionSubTypeEnum;
    /**
     * The type of visualization used for this question
     * @type {string}
     * @memberof AggregateQuestion
     */
    visualization: AggregateQuestionVisualizationEnum;
    /**
     * The survey sparrow ID of the question
     * @type {string}
     * @memberof AggregateQuestion
     */
    surveySparrowId: string;
    /**
     * The position of the question in the survey
     * @type {number}
     * @memberof AggregateQuestion
     */
    position: number;
    /**
     * The themes associated by AI to this question
     * @type {string}
     * @memberof AggregateQuestion
     */
    themes: string;
    /**
     * The choices possible in the question, e.g. for multiple choice questions
     * @type {Array<QuestionChoice>}
     * @memberof AggregateQuestion
     */
    choices?: Array<QuestionChoice>;
    /**
     * The ID of the tenant for this question
     * @type {string}
     * @memberof AggregateQuestion
     */
    tenantId: string;
    /**
     * The ID of the engagement for this question
     * @type {string}
     * @memberof AggregateQuestion
     */
    engagementId: string;
    /**
     * The QuestionAnnotations associated with this Question
     * @type {Array<QuestionAnnotation>}
     * @memberof AggregateQuestion
     */
    questionAnnotations: Array<QuestionAnnotation>;
    /**
     * The component questions of the AggregateQuestion
     * @type {Array<QuestionComponent>}
     * @memberof AggregateQuestion
     */
    components: Array<QuestionComponent>;
}

/**
    * @export
    * @enum {string}
    */
export enum AggregateQuestionTypeEnum {
    ContactForm = 'ContactForm',
    ConstantSum = 'ConstantSum',
    GroupRank = 'GroupRank',
    GroupRating = 'GroupRating',
    Matrix = 'Matrix'
}
/**
    * @export
    * @enum {string}
    */
export enum AggregateQuestionSubTypeEnum {
    MultiSelect = 'Multi-Select',
    SingleSelect = 'Single-Select',
    TextInput = 'Text Input',
    Selection = 'Selection',
    FixedTotal = 'Fixed Total',
    Uncapped = 'Uncapped'
}
/**
    * @export
    * @enum {string}
    */
export enum AggregateQuestionVisualizationEnum {
    AverageRankForEach = 'AverageRankForEach',
    Matrix = 'Matrix',
    Histogram = 'Histogram',
    HorizontalBarGraphOfAverages = 'HorizontalBarGraphOfAverages',
    TextGrid = 'TextGrid'
}

