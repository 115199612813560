import React from 'react';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  UseModalProps,
} from '@chakra-ui/react';
import { FeatureFlag } from '@keyops-cep/api-client';

import lang from '../../../utils/lang';

export function FeatureFlagModal({
  isOpen,
  onSwitchClick,
  onClose,
  featureFlags,
}: {
  isOpen: boolean;
  onSwitchClick: (
    id: string,
    enabled: boolean,
    tenantId: string,
    name: string
  ) => Promise<void>;
  onClose: UseModalProps['onClose'];
  featureFlags: FeatureFlag[];
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {lang.tenant.all.headers.actions.editFeatureFlags}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {featureFlags.map((featureFlag) => {
            return (
              <Box key={featureFlag.id} display="flex" alignItems="center">
                <Switch
                  isChecked={featureFlag.enabled}
                  onChange={(event) =>
                    onSwitchClick(
                      featureFlag.id,
                      event.target.checked,
                      featureFlag.tenantId,
                      featureFlag.name
                    )
                  }
                />
                <Text ml="3"> {featureFlag.name}</Text>
              </Box>
            );
          })}
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
