import React from 'react';
import {
  Box,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { ComparableEngagements } from '@keyops-cep/api-client';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';

import lang from '../../../utils/lang';

import { ComparableQuestions } from './comparableQuestions';

type ComparisonsTabsProps = {
  comparableEngagements: ComparableEngagements;
  currentEngagementName: string;
  comparableEngagementName: string;
};

export const ComparisonsTabs = ({
  comparableEngagements,
  currentEngagementName,
  comparableEngagementName,
}: ComparisonsTabsProps) => {
  return (
    <Tabs defaultIndex={comparableEngagements?.report ? 0 : 1} m={5}>
      <TabList>
        <Tab disabled={comparableEngagements?.report ? false : true}>
          {lang.engagement.single.comparisonAnalysis.linkTitle}
        </Tab>
        <Tab> {lang.engagement.single.comparisonAnalysis.questions.title}</Tab>
      </TabList>
      <TabIndicator bg="keyops.blue" />
      <TabPanels p={0}>
        <TabPanel p={0}>
          <Box m={5} ml={0}>
            <MDEditor.Markdown
              style={{ backgroundColor: 'transparent' }}
              source={comparableEngagements.report}
              rehypePlugins={[rehypeSanitize]}
            />
          </Box>
        </TabPanel>
        <TabPanel p={0}>
          <ComparableQuestions
            comparableQuestions={comparableEngagements.comparableQuestions}
            currentEngagementName={currentEngagementName}
            comparableEngagementName={comparableEngagementName}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
