import { AxiosResponse } from 'axios';

import { METHOD_TYPES } from '../api-constants';
import { performApiRequest } from '../api-request-builder';
import { AdminDto } from '../dto/admin.dto';
import {
  GET_ADMINS_ENDPOINT,
  POST_ADMIN_ENDPOINT,
} from '../routes/admin.routes';

export const getAdminsCall = async (): Promise<
  AxiosResponse<AdminDto[], unknown> | undefined
> => {
  let response;
  try {
    response = await performApiRequest({
      method: METHOD_TYPES.GET,
      endpoint: GET_ADMINS_ENDPOINT,
    });
  } catch (error) {
    console.error(error);
    throw error;
  }

  return response;
};

export const createAdminCall = async (
  admin: AdminDto
): Promise<AxiosResponse<AdminDto, unknown> | undefined> => {
  let response;
  try {
    response = await performApiRequest({
      method: METHOD_TYPES.POST,
      endpoint: POST_ADMIN_ENDPOINT,
      params: {},
      data: admin,
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
