/* tslint:disable */
/* eslint-disable */
/**
 * Keyops CEP
 * Customer Engagement Platform API documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { QuestionAnnotation } from './question-annotation';
import { QuestionChoice } from './question-choice';
/**
 * 
 * @export
 * @interface IndividualQuestion
 */
export interface IndividualQuestion {
    /**
     * The ID of the database object
     * @type {string}
     * @memberof IndividualQuestion
     */
    id: string;
    /**
     * The date the object was created
     * @type {string}
     * @memberof IndividualQuestion
     */
    createdAt: string;
    /**
     * The date the object was last updated
     * @type {string}
     * @memberof IndividualQuestion
     */
    updatedAt: string;
    /**
     * The label of the question
     * @type {string}
     * @memberof IndividualQuestion
     */
    label: string;
    /**
     * The type of question
     * @type {string}
     * @memberof IndividualQuestion
     */
    type: IndividualQuestionTypeEnum;
    /**
     * The subtype of the question
     * @type {string}
     * @memberof IndividualQuestion
     */
    subType?: IndividualQuestionSubTypeEnum;
    /**
     * The type of question
     * @type {string}
     * @memberof IndividualQuestion
     */
    visualization: IndividualQuestionVisualizationEnum;
    /**
     * The survey sparrow ID of the question
     * @type {string}
     * @memberof IndividualQuestion
     */
    surveySparrowId: string;
    /**
     * The position of the question in the survey
     * @type {number}
     * @memberof IndividualQuestion
     */
    position: number;
    /**
     * The themes associated by AI to this question
     * @type {string}
     * @memberof IndividualQuestion
     */
    themes: string;
    /**
     * The choices possible in the question, e.g. for multiple choice questions
     * @type {Array<QuestionChoice>}
     * @memberof IndividualQuestion
     */
    choices?: Array<QuestionChoice>;
    /**
     * The ID of the tenant for this question
     * @type {string}
     * @memberof IndividualQuestion
     */
    tenantId: string;
    /**
     * The ID of the engagement for this question
     * @type {string}
     * @memberof IndividualQuestion
     */
    engagementId: string;
    /**
     * The QuestionAnnotations associated with this Question
     * @type {Array<QuestionAnnotation>}
     * @memberof IndividualQuestion
     */
    questionAnnotations: Array<QuestionAnnotation>;
}

/**
    * @export
    * @enum {string}
    */
export enum IndividualQuestionTypeEnum {
    Consent = 'Consent',
    DateTime = 'DateTime',
    Dropdown = 'Dropdown',
    EmailInput = 'EmailInput',
    MultiChoice = 'MultiChoice',
    NumberInput = 'NumberInput',
    OpinionScale = 'OpinionScale',
    PhoneNumber = 'PhoneNumber',
    RankOrder = 'RankOrder',
    Rating = 'Rating',
    Slider = 'Slider',
    TextInput = 'TextInput',
    URLInput = 'URLInput',
    YesNo = 'YesNo'
}
/**
    * @export
    * @enum {string}
    */
export enum IndividualQuestionSubTypeEnum {
    MultiSelect = 'Multi-Select',
    SingleSelect = 'Single-Select',
    TextInput = 'Text Input',
    Selection = 'Selection',
    FixedTotal = 'Fixed Total',
    Uncapped = 'Uncapped'
}
/**
    * @export
    * @enum {string}
    */
export enum IndividualQuestionVisualizationEnum {
    Average = 'Average',
    AverageRankForEach = 'AverageRankForEach',
    Distribution = 'Distribution',
    Histogram = 'Histogram',
    HorizontalHistogram = 'HorizontalHistogram',
    HorizontalBarGraphOfAverages = 'HorizontalBarGraphOfAverages',
    ListOfResponses = 'ListOfResponses',
    MatrixRow = 'MatrixRow',
    TextColumn = 'TextColumn'
}

