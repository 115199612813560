import React from 'react';
import { Link, LinkProps } from '@chakra-ui/react';

type GenericLinkProps = {
  text: string;
  href?: string;
  color?: string;
  disable?: boolean;
  onClick?: () => void | Promise<void>;
} & LinkProps;

export const GenericLink = ({
  text,
  href,
  onClick,
  disable = false,
  color = 'keyops.blue',
  margin = '24px',
  ...restProps
}: GenericLinkProps) => {
  if (disable) {
    return (
      <Link
        color={`gray.400`}
        onClick={undefined}
        cursor={'not-allowed'}
        _hover={{
          color: 'gray.400',
        }}
        {...restProps}
      >
        {text}
      </Link>
    );
  }
  return (
    <Link
      href={href ?? undefined}
      color={color}
      onClick={onClick ?? undefined}
      {...restProps}
    >
      {text}
    </Link>
  );
};
