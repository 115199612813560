import React from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';

type MenuItemType = {
  text: string;
  action: () => void | Promise<void>;
};
type MenuButtonProps = {
  title: string;
  menuItems: MenuItemType[];
  isLoading: boolean;
};

export const CustomMenuButton = ({
  title,
  menuItems,
  isLoading,
}: MenuButtonProps) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        isDisabled={isLoading}
        background="white"
        variant="outline"
        borderColor="keyops.blue"
        color="keyops.blue"
        _hover={{
          bg: 'keyops.blue',
          color: 'white',
        }}
        _disabled={{
          bg: 'gray.100',
          color: 'gray.400',
          borderColor: 'transparent',
          cursor: 'not-allowed',
        }}
        size="sm"
        rightIcon={<ChevronDownIcon />}
      >
        {title}
      </MenuButton>

      <MenuList>
        {menuItems.map((item: MenuItemType) => (
          <MenuItem key={item.text} onClick={item.action}>
            {item.text}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
