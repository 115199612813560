/* tslint:disable */
/* eslint-disable */
/**
 * Keyops CEP
 * Customer Engagement Platform API documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface FeatureFlag
 */
export interface FeatureFlag {
    /**
     * The ID of the database object
     * @type {string}
     * @memberof FeatureFlag
     */
    id: string;
    /**
     * The date the object was created
     * @type {string}
     * @memberof FeatureFlag
     */
    createdAt: string;
    /**
     * The date the object was last updated
     * @type {string}
     * @memberof FeatureFlag
     */
    updatedAt: string;
    /**
     * The id of the tenant for this feature flag
     * @type {string}
     * @memberof FeatureFlag
     */
    tenantId: string;
    /**
     * The name of the feature flag for the tenant.
     * @type {string}
     * @memberof FeatureFlag
     */
    name: FeatureFlagNameEnum;
    /**
     * Whether the feature is enabled or not.
     * @type {boolean}
     * @memberof FeatureFlag
     */
    enabled: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum FeatureFlagNameEnum {
    EngageAIChat = 'EngageAI.Chat',
    EngageAIComparison = 'EngageAI.Comparison',
    CustomerShowComparison = 'Customer.ShowComparison'
}

