/* tslint:disable */
/* eslint-disable */
/**
 * Keyops CEP
 * Customer Engagement Platform API documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { Analysis } from '../models';
import { BulkUpdateRespondentDemographicsDto } from '../models';
import { ComparableEngagements } from '../models';
import { CreateEngagementDto } from '../models';
import { EngageAISendMessageDto } from '../models';
import { Engagement } from '../models';
import { EngagementDetailsDto } from '../models';
import { EngagementFile } from '../models';
import { MessageDto } from '../models';
import { Question } from '../models';
import { Response } from '../models';
import { UpdateEngagementDto } from '../models';
import { UpdateEngagementHideDemographicsDto } from '../models';
import { UpdateEngagementStatusDto } from '../models';
/**
 * EngagementsApi - axios parameter creator
 * @export
 */
export const EngagementsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Bulk updates an Engagement's Respondent Demographics
         * @param {BulkUpdateRespondentDemographicsDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engagementControllerBulkUpdateDemographics: async (body: BulkUpdateRespondentDemographicsDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling engagementControllerBulkUpdateDemographics.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling engagementControllerBulkUpdateDemographics.');
            }
            const localVarPath = `/api/engagements/{id}/respondent-demographics`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an Engagement
         * @param {CreateEngagementDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engagementControllerCreate: async (body: CreateEngagementDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling engagementControllerCreate.');
            }
            const localVarPath = `/api/engagements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all Engagements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engagementControllerFindAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/engagements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engagementControllerFindOne: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling engagementControllerFindOne.');
            }
            const localVarPath = `/api/engagements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Analysis for an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engagementControllerGetAnalysis: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling engagementControllerGetAnalysis.');
            }
            const localVarPath = `/api/engagements/{id}/analysis`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the ComparableEngagements for an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engagementControllerGetComparableEngagements: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling engagementControllerGetComparableEngagements.');
            }
            const localVarPath = `/api/engagements/{id}/comparable-engagements`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the list of messages for a specific OpenAI thread.      Retrieve or create an OpenAI Assistant and store it as an EngageAIAssistant into the database.      Retrieve or create an OpenAI Thread and store it as an EngageAISession into the database.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engagementControllerGetEngageAIMessages: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling engagementControllerGetEngageAIMessages.');
            }
            const localVarPath = `/api/engagements/{id}/ai-session`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the EngagementDetails for an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engagementControllerGetEngagementDetails: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling engagementControllerGetEngagementDetails.');
            }
            const localVarPath = `/api/engagements/{id}/details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Files for an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engagementControllerGetEngagementFiles: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling engagementControllerGetEngagementFiles.');
            }
            const localVarPath = `/api/engagements/{id}/engagement-files`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Questions for an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engagementControllerGetQuestions: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling engagementControllerGetQuestions.');
            }
            const localVarPath = `/api/engagements/{id}/questions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Responses for an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engagementControllerGetResponses: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling engagementControllerGetResponses.');
            }
            const localVarPath = `/api/engagements/{id}/responses`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an Engagement by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engagementControllerRemove: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling engagementControllerRemove.');
            }
            const localVarPath = `/api/engagements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sends a message to the Engagement's AI chat session for the current user
         * @param {EngageAISendMessageDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engagementControllerSendEngageAIMessage: async (body: EngageAISendMessageDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling engagementControllerSendEngageAIMessage.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling engagementControllerSendEngageAIMessage.');
            }
            const localVarPath = `/api/engagements/{id}/ai-session`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an Engagement by its ID
         * @param {UpdateEngagementDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engagementControllerUpdate: async (body: UpdateEngagementDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling engagementControllerUpdate.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling engagementControllerUpdate.');
            }
            const localVarPath = `/api/engagements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an Engagement's HideDemographics state by its ID
         * @param {UpdateEngagementHideDemographicsDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engagementControllerUpdateHideDemographics: async (body: UpdateEngagementHideDemographicsDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling engagementControllerUpdateHideDemographics.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling engagementControllerUpdateHideDemographics.');
            }
            const localVarPath = `/api/engagements/{id}/hide-demographics`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an Engagement's Status by its ID
         * @param {UpdateEngagementStatusDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        engagementControllerUpdateStatus: async (body: UpdateEngagementStatusDto, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling engagementControllerUpdateStatus.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling engagementControllerUpdateStatus.');
            }
            const localVarPath = `/api/engagements/{id}/update-status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh the survey sparrow data for the engagement
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshSurveyControllerRefreshSurvey: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling refreshSurveyControllerRefreshSurvey.');
            }
            const localVarPath = `/api/engagements/{id}/refresh-survey`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EngagementsApi - functional programming interface
 * @export
 */
export const EngagementsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Bulk updates an Engagement's Respondent Demographics
         * @param {BulkUpdateRespondentDemographicsDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerBulkUpdateDemographics(body: BulkUpdateRespondentDemographicsDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Engagement>>> {
            const localVarAxiosArgs = await EngagementsApiAxiosParamCreator(configuration).engagementControllerBulkUpdateDemographics(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create an Engagement
         * @param {CreateEngagementDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerCreate(body: CreateEngagementDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Engagement>>> {
            const localVarAxiosArgs = await EngagementsApiAxiosParamCreator(configuration).engagementControllerCreate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all Engagements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerFindAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<Engagement>>>> {
            const localVarAxiosArgs = await EngagementsApiAxiosParamCreator(configuration).engagementControllerFindAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Engagement>>> {
            const localVarAxiosArgs = await EngagementsApiAxiosParamCreator(configuration).engagementControllerFindOne(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the Analysis for an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerGetAnalysis(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Analysis>>> {
            const localVarAxiosArgs = await EngagementsApiAxiosParamCreator(configuration).engagementControllerGetAnalysis(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the ComparableEngagements for an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerGetComparableEngagements(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<ComparableEngagements>>>> {
            const localVarAxiosArgs = await EngagementsApiAxiosParamCreator(configuration).engagementControllerGetComparableEngagements(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns the list of messages for a specific OpenAI thread.      Retrieve or create an OpenAI Assistant and store it as an EngageAIAssistant into the database.      Retrieve or create an OpenAI Thread and store it as an EngageAISession into the database.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerGetEngageAIMessages(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<MessageDto>>>> {
            const localVarAxiosArgs = await EngagementsApiAxiosParamCreator(configuration).engagementControllerGetEngageAIMessages(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the EngagementDetails for an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerGetEngagementDetails(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<EngagementDetailsDto>>> {
            const localVarAxiosArgs = await EngagementsApiAxiosParamCreator(configuration).engagementControllerGetEngagementDetails(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the Files for an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerGetEngagementFiles(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<EngagementFile>>>> {
            const localVarAxiosArgs = await EngagementsApiAxiosParamCreator(configuration).engagementControllerGetEngagementFiles(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the Questions for an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerGetQuestions(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<Question>>>> {
            const localVarAxiosArgs = await EngagementsApiAxiosParamCreator(configuration).engagementControllerGetQuestions(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the Responses for an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerGetResponses(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<Response>>>> {
            const localVarAxiosArgs = await EngagementsApiAxiosParamCreator(configuration).engagementControllerGetResponses(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deletes an Engagement by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerRemove(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await EngagementsApiAxiosParamCreator(configuration).engagementControllerRemove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Sends a message to the Engagement's AI chat session for the current user
         * @param {EngageAISendMessageDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerSendEngageAIMessage(body: EngageAISendMessageDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<string>>> {
            const localVarAxiosArgs = await EngagementsApiAxiosParamCreator(configuration).engagementControllerSendEngageAIMessage(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates an Engagement by its ID
         * @param {UpdateEngagementDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerUpdate(body: UpdateEngagementDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Engagement>>> {
            const localVarAxiosArgs = await EngagementsApiAxiosParamCreator(configuration).engagementControllerUpdate(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates an Engagement's HideDemographics state by its ID
         * @param {UpdateEngagementHideDemographicsDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerUpdateHideDemographics(body: UpdateEngagementHideDemographicsDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Engagement>>> {
            const localVarAxiosArgs = await EngagementsApiAxiosParamCreator(configuration).engagementControllerUpdateHideDemographics(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates an Engagement's Status by its ID
         * @param {UpdateEngagementStatusDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerUpdateStatus(body: UpdateEngagementStatusDto, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Engagement>>> {
            const localVarAxiosArgs = await EngagementsApiAxiosParamCreator(configuration).engagementControllerUpdateStatus(body, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Refresh the survey sparrow data for the engagement
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshSurveyControllerRefreshSurvey(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await EngagementsApiAxiosParamCreator(configuration).refreshSurveyControllerRefreshSurvey(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * EngagementsApi - factory interface
 * @export
 */
export const EngagementsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Bulk updates an Engagement's Respondent Demographics
         * @param {BulkUpdateRespondentDemographicsDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerBulkUpdateDemographics(body: BulkUpdateRespondentDemographicsDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Engagement>> {
            return EngagementsApiFp(configuration).engagementControllerBulkUpdateDemographics(body, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an Engagement
         * @param {CreateEngagementDto} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerCreate(body: CreateEngagementDto, options?: AxiosRequestConfig): Promise<AxiosResponse<Engagement>> {
            return EngagementsApiFp(configuration).engagementControllerCreate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all Engagements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerFindAll(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Engagement>>> {
            return EngagementsApiFp(configuration).engagementControllerFindAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerFindOne(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Engagement>> {
            return EngagementsApiFp(configuration).engagementControllerFindOne(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Analysis for an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerGetAnalysis(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Analysis>> {
            return EngagementsApiFp(configuration).engagementControllerGetAnalysis(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the ComparableEngagements for an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerGetComparableEngagements(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<ComparableEngagements>>> {
            return EngagementsApiFp(configuration).engagementControllerGetComparableEngagements(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the list of messages for a specific OpenAI thread.      Retrieve or create an OpenAI Assistant and store it as an EngageAIAssistant into the database.      Retrieve or create an OpenAI Thread and store it as an EngageAISession into the database.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerGetEngageAIMessages(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<MessageDto>>> {
            return EngagementsApiFp(configuration).engagementControllerGetEngageAIMessages(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the EngagementDetails for an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerGetEngagementDetails(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<EngagementDetailsDto>> {
            return EngagementsApiFp(configuration).engagementControllerGetEngagementDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Files for an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerGetEngagementFiles(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<EngagementFile>>> {
            return EngagementsApiFp(configuration).engagementControllerGetEngagementFiles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Questions for an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerGetQuestions(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Question>>> {
            return EngagementsApiFp(configuration).engagementControllerGetQuestions(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Responses for an Engagement by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerGetResponses(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<Response>>> {
            return EngagementsApiFp(configuration).engagementControllerGetResponses(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an Engagement by its ID
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerRemove(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return EngagementsApiFp(configuration).engagementControllerRemove(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sends a message to the Engagement's AI chat session for the current user
         * @param {EngageAISendMessageDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerSendEngageAIMessage(body: EngageAISendMessageDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<string>> {
            return EngagementsApiFp(configuration).engagementControllerSendEngageAIMessage(body, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an Engagement by its ID
         * @param {UpdateEngagementDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerUpdate(body: UpdateEngagementDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Engagement>> {
            return EngagementsApiFp(configuration).engagementControllerUpdate(body, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an Engagement's HideDemographics state by its ID
         * @param {UpdateEngagementHideDemographicsDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerUpdateHideDemographics(body: UpdateEngagementHideDemographicsDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Engagement>> {
            return EngagementsApiFp(configuration).engagementControllerUpdateHideDemographics(body, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an Engagement's Status by its ID
         * @param {UpdateEngagementStatusDto} body 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async engagementControllerUpdateStatus(body: UpdateEngagementStatusDto, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<Engagement>> {
            return EngagementsApiFp(configuration).engagementControllerUpdateStatus(body, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh the survey sparrow data for the engagement
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshSurveyControllerRefreshSurvey(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return EngagementsApiFp(configuration).refreshSurveyControllerRefreshSurvey(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EngagementsApi - object-oriented interface
 * @export
 * @class EngagementsApi
 * @extends {BaseAPI}
 */
export class EngagementsApi extends BaseAPI {
    /**
     * 
     * @summary Bulk updates an Engagement's Respondent Demographics
     * @param {BulkUpdateRespondentDemographicsDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementsApi
     */
    public async engagementControllerBulkUpdateDemographics(body: BulkUpdateRespondentDemographicsDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Engagement>> {
        return EngagementsApiFp(this.configuration).engagementControllerBulkUpdateDemographics(body, id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create an Engagement
     * @param {CreateEngagementDto} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementsApi
     */
    public async engagementControllerCreate(body: CreateEngagementDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<Engagement>> {
        return EngagementsApiFp(this.configuration).engagementControllerCreate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get all Engagements
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementsApi
     */
    public async engagementControllerFindAll(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<Engagement>>> {
        return EngagementsApiFp(this.configuration).engagementControllerFindAll(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get an Engagement by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementsApi
     */
    public async engagementControllerFindOne(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Engagement>> {
        return EngagementsApiFp(this.configuration).engagementControllerFindOne(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the Analysis for an Engagement by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementsApi
     */
    public async engagementControllerGetAnalysis(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Analysis>> {
        return EngagementsApiFp(this.configuration).engagementControllerGetAnalysis(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the ComparableEngagements for an Engagement by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementsApi
     */
    public async engagementControllerGetComparableEngagements(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<ComparableEngagements>>> {
        return EngagementsApiFp(this.configuration).engagementControllerGetComparableEngagements(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Returns the list of messages for a specific OpenAI thread.      Retrieve or create an OpenAI Assistant and store it as an EngageAIAssistant into the database.      Retrieve or create an OpenAI Thread and store it as an EngageAISession into the database.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementsApi
     */
    public async engagementControllerGetEngageAIMessages(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<MessageDto>>> {
        return EngagementsApiFp(this.configuration).engagementControllerGetEngageAIMessages(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the EngagementDetails for an Engagement by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementsApi
     */
    public async engagementControllerGetEngagementDetails(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<EngagementDetailsDto>> {
        return EngagementsApiFp(this.configuration).engagementControllerGetEngagementDetails(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the Files for an Engagement by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementsApi
     */
    public async engagementControllerGetEngagementFiles(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<EngagementFile>>> {
        return EngagementsApiFp(this.configuration).engagementControllerGetEngagementFiles(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the Questions for an Engagement by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementsApi
     */
    public async engagementControllerGetQuestions(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<Question>>> {
        return EngagementsApiFp(this.configuration).engagementControllerGetQuestions(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the Responses for an Engagement by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementsApi
     */
    public async engagementControllerGetResponses(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<Response>>> {
        return EngagementsApiFp(this.configuration).engagementControllerGetResponses(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Deletes an Engagement by its ID
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementsApi
     */
    public async engagementControllerRemove(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return EngagementsApiFp(this.configuration).engagementControllerRemove(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Sends a message to the Engagement's AI chat session for the current user
     * @param {EngageAISendMessageDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementsApi
     */
    public async engagementControllerSendEngageAIMessage(body: EngageAISendMessageDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<string>> {
        return EngagementsApiFp(this.configuration).engagementControllerSendEngageAIMessage(body, id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Updates an Engagement by its ID
     * @param {UpdateEngagementDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementsApi
     */
    public async engagementControllerUpdate(body: UpdateEngagementDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Engagement>> {
        return EngagementsApiFp(this.configuration).engagementControllerUpdate(body, id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Updates an Engagement's HideDemographics state by its ID
     * @param {UpdateEngagementHideDemographicsDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementsApi
     */
    public async engagementControllerUpdateHideDemographics(body: UpdateEngagementHideDemographicsDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Engagement>> {
        return EngagementsApiFp(this.configuration).engagementControllerUpdateHideDemographics(body, id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Updates an Engagement's Status by its ID
     * @param {UpdateEngagementStatusDto} body 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementsApi
     */
    public async engagementControllerUpdateStatus(body: UpdateEngagementStatusDto, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<Engagement>> {
        return EngagementsApiFp(this.configuration).engagementControllerUpdateStatus(body, id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Refresh the survey sparrow data for the engagement
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EngagementsApi
     */
    public async refreshSurveyControllerRefreshSurvey(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return EngagementsApiFp(this.configuration).refreshSurveyControllerRefreshSurvey(id, options).then((request) => request(this.axios, this.basePath));
    }
}
