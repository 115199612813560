import React from 'react';
import MDEditor from '@uiw/react-md-editor';
import { FieldHookConfig, useField } from 'formik';

export const CustomMarkdownInput: React.FC<FieldHookConfig<string>> = (
  props
) => {
  const [field, meta, helpers] = useField(props.name); // Get Formik field, meta, and helpers

  return (
    <div data-color-mode="light">
      <MDEditor
        height={100}
        value={field.value}
        onChange={(value) => {
          helpers.setValue(value); // Update Formik field value when MDEditor value changes
        }}
      />
      {/* Accessing the meta prop if needed */}
      {meta.touched && meta.error && <div>{meta.error}</div>}
    </div>
  );
};
