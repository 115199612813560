/* tslint:disable */
/* eslint-disable */
/**
 * Keyops CEP
 * Customer Engagement Platform API documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * 
 * @export
 * @interface CreateTenantDto
 */
export interface CreateTenantDto {
    /**
     * The name of the tenant
     * @type {string}
     * @memberof CreateTenantDto
     */
    name: string;
    /**
     * The country the tenant is in
     * @type {string}
     * @memberof CreateTenantDto
     */
    country: CreateTenantDtoCountryEnum;
    /**
     * Whether or not the tenant is active
     * @type {boolean}
     * @memberof CreateTenantDto
     */
    isActive: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateTenantDtoCountryEnum {
    US = 'US',
    CAN = 'CAN'
}

