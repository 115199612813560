import * as React from 'react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
  chakra,
  LinkBox,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import { buildResponsesTableColumns, ResponseTableHeader } from '..';

interface ResponsesTableProps {
  data: ResponseTableHeader[];
  setIsOpen: (arg0: boolean) => void;
  setSelectedResponseId: React.Dispatch<React.SetStateAction<string | null>>;
}

export const ResponsesTable = ({
  data,
  setIsOpen,
  setSelectedResponseId,
}: ResponsesTableProps) => {
  // Build the table logic
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const table = useReactTable({
    columns: buildResponsesTableColumns(setIsOpen, setSelectedResponseId),
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  // Handle hovered row that changes background color
  const [hoveredRowId, setHoveredRowId] = React.useState('');

  return (
    <TableContainer
      padding={5}
      margin={5}
      borderRadius="lg"
      borderStyle="solid"
      borderWidth="thin"
      borderColor="gray.200"
    >
      <Table variant="unstyled">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <Th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    cursor={'pointer'}
                    fontSize={'sm'}
                    textTransform={'capitalize'}
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}

                    <chakra.span pl="4">
                      {header.column.getIsSorted() ? (
                        header.column.getIsSorted() === 'desc' ? (
                          <TriangleDownIcon aria-label="sorted descending" />
                        ) : (
                          <TriangleUpIcon aria-label="sorted ascending" />
                        )
                      ) : null}
                    </chakra.span>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <LinkBox
              as={Tr}
              key={row.id}
              onMouseEnter={() => setHoveredRowId(row.id)}
              backgroundColor={
                hoveredRowId === row.id ? `gray.200` : `transparent`
              }
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <Td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                );
              })}
            </LinkBox>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
