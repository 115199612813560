import { AxiosInstance } from "axios";

import {
  AnalysesApi,
  AuthenticationApi,
  ComparableEngagementsApi,
  ComparableQuestionsApi,
  Configuration,
  EngageAiSessionApi,
  EngagementFilesApi,
  EngagementsApi,
  FeatureFlagsApi,
  QuestionAnalysesApi,
  TenantsApi,
} from "./generated";

type ApiConstructor<T> = new (
  configuration: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) => T;

const initializeApiClient = <T>(
  ApiClass: ApiConstructor<T>,
  basePath: string,
  accessToken: string,
  axiosInstance: AxiosInstance
): T => {
  return new ApiClass(
    new Configuration({
      basePath,
      accessToken,
    }),
    undefined,
    axiosInstance
  );
};

export class CepApiClient {
  public analysesApi: AnalysesApi;
  public authenticationApi: AuthenticationApi;
  public comparableEngagementsApi: ComparableEngagementsApi;
  public comparableQuestionsApi: ComparableQuestionsApi;
  public engagementsApi: EngagementsApi;
  public engageAiSessionApi: EngageAiSessionApi;
  public engagementFilesApi: EngagementFilesApi;
  public featureFlagsApi: FeatureFlagsApi;
  public questionAnalysesApi: QuestionAnalysesApi;
  public tenantsApi: TenantsApi;

  constructor(
    basePath: string,
    accessToken: string,
    axiosInstance: AxiosInstance
  ) {
    this.analysesApi = initializeApiClient(
      AnalysesApi,
      basePath,
      accessToken,
      axiosInstance
    );
    this.authenticationApi = initializeApiClient(
      AuthenticationApi,
      basePath,
      accessToken,
      axiosInstance
    );
    this.engagementsApi = initializeApiClient(
      EngagementsApi,
      basePath,
      accessToken,
      axiosInstance
    );
    this.engageAiSessionApi = initializeApiClient(
      EngageAiSessionApi,
      basePath,
      accessToken,
      axiosInstance
    );
    this.engagementFilesApi = initializeApiClient(
      EngagementFilesApi,
      basePath,
      accessToken,
      axiosInstance
    );
    this.comparableEngagementsApi = initializeApiClient(
      ComparableEngagementsApi,
      basePath,
      accessToken,
      axiosInstance
    );
    this.comparableQuestionsApi = initializeApiClient(
      ComparableQuestionsApi,
      basePath,
      accessToken,
      axiosInstance
    );
    this.featureFlagsApi = initializeApiClient(
      FeatureFlagsApi,
      basePath,
      accessToken,
      axiosInstance
    );
    this.questionAnalysesApi = initializeApiClient(
      QuestionAnalysesApi,
      basePath,
      accessToken,
      axiosInstance
    );
    this.tenantsApi = initializeApiClient(
      TenantsApi,
      basePath,
      accessToken,
      axiosInstance
    );
  }
}
