export const TENANT_COUNTRIES = {
  CA: 'Canada',
  US: 'USA',
};

export type TenantResponseDto = {
  id: string;
  name: string;
  country: string;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type UpdateTenantDto = {
  id: string;
  name: string;
  country: string;
  isActive: boolean;
};

export type CreateTenantDto = Omit<UpdateTenantDto, 'id'>;

export type UpdateTenantIsActiveDto = {
  isActive: boolean;
};
