import React from 'react';
import { Text } from '@chakra-ui/react';

export const CustomErrorText = ({ errorMessage }: { errorMessage: string }) => {
  return (
    <Text as="sub" color="red" paddingTop={0} paddingLeft={3}>
      {errorMessage}
    </Text>
  );
};
