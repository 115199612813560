import React, { useRef } from 'react';
import { FiUpload } from 'react-icons/fi';

import lang from '../../../utils/lang';
import GenericButton from '../../GenericButton';

const FileUpload = ({
  label,
  handleChange,
}: {
  label?: string;
  handleChange: (result: string | ArrayBuffer | null) => void;
}) => {
  const fileUpload = useRef<HTMLInputElement>(null);
  const loadFile = (event: React.FormEvent<HTMLInputElement>) => {
    if (!event.currentTarget.files || !event.currentTarget.files[0]) {
      return;
    }
    const reader = new FileReader();
    reader.readAsText(event.currentTarget.files[0]);

    reader.onload = async () => {
      handleChange(reader.result);
    };
  };
  return (
    <>
      <input
        accept=".csv"
        style={{ display: 'none' }}
        id="fileUpload"
        multiple
        type="file"
        onChange={loadFile}
        ref={fileUpload}
      />
      <GenericButton
        type="button"
        text={label ?? lang.components.fileUpload.defaultLabel}
        leftIcon={<FiUpload aria-label="upload-icon" />}
        background="white"
        onClick={() => {
          if (fileUpload.current) {
            fileUpload.current.click();
          }
        }}
        margin={0}
      />
    </>
  );
};

export default FileUpload;
