import React, { useRef } from 'react';
import { Button } from '@chakra-ui/react';

type GenericButtonProps = {
  type: 'button' | 'submit' | 'reset';
  text: string;
  onClick: () => void | Promise<void>;
  background?: string;
  innerRef?: React.MutableRefObject<HTMLButtonElement | null>;
  margin?: number;
  rightIcon?:
    | React.ReactElement<string, string | React.JSXElementConstructor<string>>
    | undefined;
  leftIcon?:
    | React.ReactElement<string, string | React.JSXElementConstructor<string>>
    | undefined;
  isLoading?: boolean;
  isDisabled?: boolean;
  size?: string;
};

const GenericButton = ({
  type,
  text,
  onClick,
  innerRef,
  background = 'keyops.blue',
  margin = 5,
  rightIcon,
  leftIcon,
  isLoading = false,
  isDisabled = false,
  size = 'sm',
}: GenericButtonProps) => {
  const defaultRef = useRef(null);

  return (
    <Button
      isDisabled={isDisabled}
      type={type}
      background={background}
      variant={background === `keyops.blue` ? '' : 'outline'}
      borderColor={background === `keyops.blue` ? 'transparent' : 'keyops.blue'}
      color={background === `keyops.blue` ? 'white' : 'keyops.blue'}
      _hover={
        background !== `keyops.blue` && isLoading
          ? {
              bg: 'white',
              color: 'gray.400',
            }
          : {
              bg: 'keyops.blue',
              color: 'white',
            }
      }
      _disabled={{
        bg: 'gray.100',
        color: 'gray.400',
        borderColor: 'gray.100',
        cursor: 'not-allowed',
      }}
      size={size}
      isLoading={isLoading}
      _loading={{ color: 'gray.400', borderColor: 'gray.200', bg: 'white' }}
      loadingText="Loading"
      onClick={onClick}
      ref={innerRef ?? defaultRef}
      margin={margin}
      rightIcon={rightIcon}
      leftIcon={leftIcon}
    >
      {text}
    </Button>
  );
};

export default GenericButton;
