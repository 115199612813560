import React from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  UseModalProps,
} from '@chakra-ui/react';

import { CreateTenantDto, UpdateTenantDto } from '../../../api/dto/tenant.dto';
import lang from '../../../utils/lang';
import { TenantForm } from '../../Tenants/components/tenantForm';

export function TenantModal({
  isOpen,
  onFormSubmit,
  onClose,
  tenant,
}: {
  isOpen: boolean;
  onFormSubmit: (values: CreateTenantDto | UpdateTenantDto) => Promise<void>;
  onClose: UseModalProps['onClose'];
  tenant?: UpdateTenantDto;
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {tenant && 'id' in tenant
            ? lang.tenant.actions.update
            : lang.tenant.actions.add}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <TenantForm
            tenant={tenant}
            onSubmit={onFormSubmit}
            onClose={onClose}
          />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
