import React from 'react';
import { FormControl, FormLabel, Select } from '@chakra-ui/react';

import lang from '../../utils/lang';

export const CustomSelectInput = ({
  name,
  label,
  value,
  placeholder,
  options,
  onChange,
  onBlur,
  isMandatory,
}: {
  name: string;
  label: string;
  value: string;
  placeholder?: string;
  options: Record<string, string>;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur: (e: React.FocusEvent<unknown, Element>) => void | undefined;
  isMandatory: boolean;
}) => {
  return (
    <FormControl padding={3}>
      <FormLabel htmlFor="status">
        {isMandatory
          ? label.charAt(0).toUpperCase() + label.slice(1) + '*'
          : label.charAt(0).toUpperCase() + label.slice(1)}
      </FormLabel>
      <Select
        id={name}
        name={name}
        value={value}
        placeholder={placeholder ?? lang.input.select}
        onChange={onChange}
        onBlur={onBlur}
      >
        {Object.keys(options).map((value, key) => (
          <option key={key}>{options[value]}</option>
        ))}
      </Select>
    </FormControl>
  );
};
