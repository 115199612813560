import React from 'react';
import {
  createSearchParams,
  Navigate,
  Outlet,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import Loading from '../../components/Loading';
import { TENANTS_PAGE } from '../../utils/internal-routes';

import useAuth from './useAuth';

const PublicRoutes: React.FunctionComponent = () => {
  const { isLoggedInFromApi, loading } = useAuth();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  if (loading) {
    //loading during axios call for auth check
    return <Loading />;
  }
  return isLoggedInFromApi ? (
    //Navigate user to the Tenants page if they are already logged in
    <Navigate
      to={{
        pathname: TENANTS_PAGE,
        search: `?${createSearchParams(searchParams)}`,
      }}
      state={Object.assign({}, location.state)}
      replace
    />
  ) : (
    <Outlet />
  );
};

export default PublicRoutes;
